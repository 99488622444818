import React from 'react';
import { ContainerProps, Grid } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import BlockContainer from '../../BlockContainer';
import CTATileBlock from '../CTATileBlock';
import { Image } from '../types';

const css = bem('CTATilesBlock');

type CTATileAnimation = 'scale';
type CTATileVariant = 'segment' | 'square';

type CTATileBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  backgroundImage?: Image;

  icon?: string;
  subtitle?: string;
  title: string;
  to: string;
  variant?: CTATileVariant;
};

type CTATilesBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  blocks?: CTATileBlockProps[];
  className?: string;
  container?: ContainerProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridProps?: any;
  imageProps?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemGridProps?: any;
  itemsPerRow?: number;
  style?: Record<string, string>;
  target?: string;
  variant?: CTATileVariant;
};

const CTATilesBlock = ({
  _id,
  animation,
  variant,
  container,
  blocks,
  className,
  gridProps,
  itemGridProps,
  itemsPerRow,
  imageProps,
}: CTATilesBlockProps): JSX.Element | null => {
  if (!blocks || blocks.length < 0) return null;

  const centeredGrid = itemsPerRow && blocks.length < itemsPerRow;
  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
        variant,
      })
        .mix(className)
        .toString()}
    >
      <Grid
        className={className}
        doubling
        columns={itemsPerRow}
        centered={centeredGrid}
        {...gridProps}
      >
        {blocks.map((block) => (
          <Grid.Column key={block._id}>
            <CTATileBlock
              animation={animation}
              variant={variant}
              {...itemGridProps}
              {...block}
              maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
              imageProps={imageProps}
            />
          </Grid.Column>
        ))}
      </Grid>
    </BlockContainer>
  );
};

CTATilesBlock.defaultProps = {
  animation: undefined,
  blocks: [],
  container: {},
  className: undefined,
  imageProps: {},
  itemGridProps: {},
  itemsPerRow: 2,
  gridProps: {},
  target: undefined,
  style: undefined,
  variant: undefined,
};

export default CTATilesBlock;
