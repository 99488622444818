import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { matchDefinedValues } from '../../utils';
import { useAutoRefresh } from '../../utils/hooks';
import { replaceValues } from '../../utils/stringUtils';
import { useStoreCollectionSync } from '../../utils/useVersionSync';
import FeedbackPopupBlock from '../../workshops/blocks/FeedbackPopupBlock';
import WorkshopLiveBlock from '../../workshops/blocks/WorkshopLiveBlock';
import { throttledFetchWorkshops } from '../../workshops/store/workshops.actions';
import { upcomingLiveSession } from '../../workshops/utils/session.utils';

const CloudTvBlock = ({ title, container, countdown, videoProps, workshopFilter }) => {
  const { allowFullScreen } = videoProps;

  const agenda = useSelector(userAgenda);
  const filteredAgenda = agenda.filter(matchDefinedValues(workshopFilter));

  const now = useAutoRefresh(60000);

  const currentLiveStreams = upcomingLiveSession(filteredAgenda, now);

  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });

  if (!currentLiveStreams) {
    // Keep current feedback open
    return (
      <>
        <FeedbackPopupBlock item={{}} />
      </>
    );
  }

  return (
    <>
      <FeedbackPopupBlock item={currentLiveStreams} />
      <div className="block--cloudtv">
        <WorkshopLiveBlock
          defaultActive
          countdown={countdown}
          allowFullScreen={allowFullScreen}
          item={currentLiveStreams}
          containerType={container?.type}
          title={replaceValues(container?.header || title, { item: currentLiveStreams })}
          videoProps={videoProps}
        />
      </div>
    </>
  );
};

CloudTvBlock.defaultProps = {
  container: undefined,
  countdown: undefined,
  title: undefined,
  videoProps: {},
  workshopFilter: {},
};

CloudTvBlock.propTypes = {
  title: PropTypes.string,
  container: PropTypes.object,
  countdown: PropTypes.object,
  videoProps: PropTypes.shape({
    allowFullScreen: PropTypes.bool,
  }),
  workshopFilter: PropTypes.object,
};

export default connect((state) => ({
  workshops: state.workshops.workshops,
}))(CloudTvBlock);
