/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { parse } from 'querystring';
import { fetchAgenda } from '../agenda/store/agenda.actions';
import authService from '../core/services/auth.service';
import platformService from '../core/services/platform.service';
import { dispatchData, fetchRegistrations, fetchUser } from '../store/actions';
import { authLogin, authLogout } from '../store/auth/auth.actions';
import { getFirebase } from '../store/effects/firebase.effects';
import mainSaga from '../store/mainSaga';
import reducers from '../store/reducers';
import { setAppointments } from '../store/reducers/appointments';
import { setStoreUser } from '../store/reducers/user';

export function getUrlParameters(url) {
  // eslint-disable-next-line no-param-reassign, no-undef
  if (!url) url = window.location.href;
  const index = url.indexOf('?');
  if (index === -1) return {};
  return parse(url.slice(index + 1));
}

export function getParameterByName(name, url) {
  // eslint-disable-next-line no-param-reassign, no-undef
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape, no-param-reassign
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class Store {
  constructor() {
    const { eventId } = window.__DATA__;
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const sagaMiddleware = createSagaMiddleware();
    this.reduxStore = createStore(
      reducers,
      undefined,
      composeEnhancers(applyMiddleware(thunk, sagaMiddleware)),
    );

    this.listeners = [];
    this.eventId = eventId;
    this.isInit = false;
    this.initFromStorage();
    sagaMiddleware.setContext({
      appCraftStore: this,
      setGlobalContext: (context) => sagaMiddleware.setContext(context),
    });
    sagaMiddleware.run(mainSaga);
    this.sagasRunner = sagaMiddleware;
    // this.init();
  }

  getLocalStorageKey() {
    return `platform-${this.eventId}`;
  }

  async checkImpersonator(impersonationToken) {
    const result = await authService.impersonate(impersonationToken);
    if (result.success) {
      const { user, token, sessionId, impersonator } = result;
      this.setUser(user, token, sessionId, impersonator);
      this.renewFirebaseToken();
      this.refresh();

      // Remove token from url
      if (window.history && window.history.replaceState) {
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
      }
    }
  }

  checkLogin() {
    const eventData = localStorage.getItem(this.getLocalStorageKey());
    if (eventData) {
      const { user, token, sessionId } = JSON.parse(eventData) || {};
      if (user && token) {
        this.setUser(user, token, sessionId);
        this.renewFirebaseToken();
      }
    }
  }

  async checkConnection() {
    const impersonationToken = getParameterByName('impersonationToken');
    if (impersonationToken) {
      await this.checkImpersonator(impersonationToken);
    } else {
      this.checkLogin();
    }
  }

  init() {
    setTimeout(this.refresh, 10);
  }

  setUser(user, token, sessionId, impersonator) {
    this.user = user;
    if (user) {
      this.reduxStore.dispatch(setStoreUser(user));
      this.userId = user._id;
      this.token = token;
      this.sessionId = sessionId;
      this.impersonator = impersonator;
    } else {
      this.eventId = null;
      this.userId = null;
      this.sessionId = null;
      this.impersonator = null;
    }
  }

  async disconnect() {
    localStorage.removeItem(this.getLocalStorageKey());
    this.token = null;
    this.reduxStore.dispatch(authLogout());
    this.user = null;
  }

  hasSavedUser() {
    return !!localStorage.getItem(this.getLocalStorageKey());
  }

  async onLogin(res, { stayConnected }) {
    if (stayConnected) {
      localStorage.setItem(
        this.getLocalStorageKey(),
        JSON.stringify({ user: res.user, token: res.token, sessionId: res.sessionId }),
      );
    }
    this.setUser(res.user, res.token, res.sessionId);
    this.reduxStore.dispatch(authLogin(res));
    this.refresh();
  }

  async tryLogin(request, stayConnected) {
    try {
      // const extraFields = ['thumbnail', 'networking'];
      const res = await request;
      if (res.success) {
        this.onLogin(res, { stayConnected });
      }
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.code, ' : ', error.message);
      if (error.message === 'Failed to fetch') throw error; // Propagate
      return null;
    }
  }

  async autoLogin(token, stayConnected) {
    return this.tryLogin(authService.autoLogin(token, 'participants', {}), stayConnected);
  }

  async login(login, password, stayConnected, options) {
    let collection = 'participants';

    // Specific for apirubi  // TODO : set config to delete this code
    if (this.eventId === 'nopMU0TusbbGPM') {
      collection = '';
    }
    return this.tryLogin(authService.login(login, password, collection, options), stayConnected);
  }

  async resetPassword(resetToken, password, autoLogin, stayConnected) {
    let collection = 'participants';

    // Specific for apirubi  // TODO : set config to delete this code
    if (this.eventId === 'nopMU0TusbbGPM') {
      collection = '';
    }
    return this.tryLogin(
      authService.resetPassword(resetToken, password, collection, {
        autoLogin,
      }),
      stayConnected,
    );
  }

  async renewFirebaseToken() {
    const res = await authService.renewFirebaseToken(this.token);

    this.reduxStore.dispatch(
      authLogin({
        user: this.user,
        token: this.token,
        ...res,
      }),
    );
  }

  async getFirebase() {
    return this.sagasRunner
      .run(function* _getFirebase() {
        return yield getFirebase();
      })
      .toPromise();
  }

  isLoggedIn() {
    return !!this.user;
  }

  async updateUser(userPatch) {
    const res = await platformService.patchUser(userPatch);
    this.reduxStore.dispatch(fetchUser());

    const user = localStorage.getItem(this.getLocalStorageKey());

    if (user) {
      const item = JSON.parse(user);
      localStorage.setItem(
        this.getLocalStorageKey(),
        JSON.stringify({
          user: { ...item.user, ...userPatch },
          token: item.token,
          sessionId: item.sessionId,
        }),
      );
    }

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePassword(newPassword, previousPassword) {
    const res = await platformService.updatePassword(newPassword, previousPassword);
    return res;
  }

  dispatch() {
    const state = this.getState();
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners) {
      listener(state);
    }
  }

  getState() {
    return {
      ...this.data,
    };
  }

  refresh = async () => {
    this.isInit = true;
    this.dispatch();
    if (!this.token || this.isPreview) return;

    const [data, appointments] = await Promise.all([
      platformService.fetchData(),
      platformService.fetchAppointments(),
    ]);
    // eslint-disable-next-line consistent-return
    if (data.error) return this.logout();

    const { workshops } = data;
    // eslint-disable-next-line consistent-return
    if (workshops.error) return this.logout();

    this.reduxStore.dispatch(dispatchData(data));
    this.reduxStore.dispatch(fetchRegistrations());
    this.reduxStore.dispatch(setAppointments(appointments));
    this.reduxStore.dispatch(fetchAgenda());
    this.reduxStore.dispatch(fetchUser());
  };

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    };
  }

  initFromStorage() {
    try {
      // eslint-disable-next-line no-undef
      const savedUser = localStorage.getItem(this.getLocalStorageKey());

      if (savedUser) {
        const { user, token } = JSON.parse(savedUser);
        if (user && token) {
          this.initForUser(user, token);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  initForUser = async (user, token) => {
    if (user) {
      this.user = user;
      this.userId = user._id;
      this.token = token;
      await this.init();
    }
  };

  async logout() {
    // eslint-disable-next-line no-undef
    localStorage.removeItem(this.getLocalStorageKey());
    this.userId = null;
    this.eventId = null;
    this.token = null;
    this.dispatch();
  }
}

const store = new Store();
function connect(ComponentToWrap) {
  class StoreComponent extends Component {
    constructor(props, context) {
      super(props, context);

      this.unsubscribe = store.subscribe((state) => this.setState(state));

      this.state = store.getState();
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      if (!store.isInit) {
        return null;
      }
      return <ComponentToWrap {...this.state} {...this.props} />;
    }
  }

  // on retourne notre wrapper
  return StoreComponent;
}

store.connect = connect;
export default store;
