import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-media';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Item, Segment } from 'semantic-ui-react';
import DescriptionBlock from '../components/sponsors/DescriptionBlock';
import VideosBlock from '../components/video/VideosBlock';
import WorkshopsMatchingBlock from '../components/workshops/WorkshopsMatchingBlock';
import { TrackingContext } from '../Context';
import { useIsPreview } from '../config/preview.context';
import { useScreenConfig } from '../config/screens.context';
import { eventTags } from '../core/trackers/events';
import { userProptypes } from '../propTypes';
import store from '../shared/Store';
import { sidebarBlockComponents } from '../sponsors/blocks';
import SponsorChat from '../sponsors/blocks/SponsorChat';
import { SponsorBannerLogo } from '../sponsors/Sponsor';
import { getString } from '../utils';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import { useStoreVersionSync } from '../utils/useVersionSync';
import VideoEmbed from '../components/VideoEmbed';
import { fetchProgram } from './store/programs.actions';
import SidebarBlocks from '../components/SidebarBlocks';

const translationPrefix = 'programs.program';

export const Program = ({ program }) => {
  const {
    _id,
    collection,
    name,
    description,
    logo,
    url,
    shortDescription,
    videos,
    video,
  } = program;
  const programs = useScreenConfig('programs');
  const { rightSidebar, banner, enableChat } = programs?.program ?? {};
  const isPreview = useIsPreview();
  const { trackEvent } = useContext(TrackingContext);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const marginBottom = matches.mobile ? 70 : 0;

  function trackGoToPage() {
    trackEvent(eventTags.PROGRAM_GO_TO_PAGE, {
      userId: store.user._id,
      item: program,
    });
  }

  const rightWidth = rightSidebar ? rightSidebar.width || 4 : 0;

  return (
    <div className={`screen--type-program ${collection}--${_id}`} style={{ marginBottom }}>
      <Button
        style={{ backgroundColor: 'transparent', paddingLeft: 0, color: 'white' }}
        as={Link}
        to="/ovhcloud-programs"
      >
        <Icon name="angle left" /> {getString(`${translationPrefix}.back-to-programs`)}
      </Button>
      <Grid>
        <Grid.Column width={16 - rightWidth}>
          <Segment>
            <SponsorBannerLogo banner={banner} logo={logo} />
            <Header as="h2" style={{ marginBottom: 0 }}>
              {name}
            </Header>

            <Item>
              <Item.Content>
                {url && (
                  <Item.Description>
                    <a href={url} target="_blank" rel="noreferrer noopener" onClick={trackGoToPage}>
                      {url}
                    </a>
                  </Item.Description>
                )}
              </Item.Content>
            </Item>

            <Item>
              <Item.Content>
                <Item.Description>{shortDescription}</Item.Description>
                <br />
                {video && <VideoEmbed icon="play" url={video.uri} allowFullScreen />}
              </Item.Content>
            </Item>
          </Segment>
          {description && <DescriptionBlock description={description} />}
          <VideosBlock videos={videos} />
          <WorkshopsMatchingBlock field="programs" value={_id} />
        </Grid.Column>

        <SidebarBlocks
          sidebar={rightSidebar}
          blockComponents={sidebarBlockComponents}
          sharedProps={{
            item: program,
            translationPrefix,
          }}
        />
      </Grid>
      {!isPreview && enableChat && (
        <SponsorChat
          sponsorId={_id}
          sponsor={program}
          translationPrefix={translationPrefix}
          type="program"
        />
      )}
    </div>
  );
};

Program.defaultProps = {
  program: {},
};

Program.propTypes = {
  program: PropTypes.shape(userProptypes),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function EnsureProgram({ program, ...props }) {
  const programId = program?._id;

  useStoreVersionSync({
    fetcher: fetchProgram,
    resourceType: 'program',
    id: programId,
    actualVersion: program?.version,
  });

  if (!program) return null; // Loading ?
  return <Program program={program} {...props} />;
}

export default connect((state, props) => {
  const { id } = props.match.params;
  return {
    program: state.programs.programs.find((s) => (s.slug ? s.slug === id : s._id === id)),
  };
})(EnsureProgram);
