import React from 'react';
import PropTypes from 'prop-types';
import CdnImage from './CdnImage';

const SquareImage = ({ imageSize, cdnOptions, ...props }) => (
  <CdnImage
    {...props}
    maxWidth={imageSize}
    maxHeight={imageSize}
    cdnOptions={{ org_if_sml: 0, ...cdnOptions }}
  />
);

SquareImage.defaultProps = {
  cdnOptions: undefined,
  imageType: 'auto',
  func: 'crop',
  src: undefined,
  srcSet: true,
};

SquareImage.propTypes = {
  cdnOptions: PropTypes.object,
  imageType: PropTypes.oneOf(['auto', 'original']),
  func: PropTypes.oneOf(['bound', 'crop', 'fit']),
  imageSize: PropTypes.number.isRequired,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srcSet: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SquareImage;
