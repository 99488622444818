/* eslint-disable no-console */
import store from '../../shared/Store';
import platformService from '../services/platform.service';
import { trackEvent as tcTrackEvent, trackPage as tcTrackPage } from './tagCommanderTracker';
import { generateEvent, getCurrentPage, splitPath } from './utils';

export function trackEvent(eventName, payload) {
  if (process.env.NODE_ENV !== 'production') {
    // console.log(`trackEvent ${eventName} - blocked in dev\n`, payload);
    return;
  }
  const isUserConnected = store?.user?._id;
  if (!isUserConnected) return;

  // Future switch on tracker types
  const stats = window.__DATA__?.screens?.stats || {};

  if (stats[eventName]) {
    const event = generateEvent(eventName, payload, stats);
    if (event) {
      // Push to server...
      platformService.stats(event.tag, { ...event.payload, from: window.location.href });
    }
  }

  if (window.trackEvent) {
    window.trackEvent(eventName, payload);
  }

  // Future switch on tracker types
  if (window.__DATA__?.tagCommander?.trackEvents) {
    const event = generateEvent(eventName, payload, window.__DATA__.tagCommander.trackEvents);
    if (event) {
      tcTrackEvent(event);
    }
  }
}

export function trackPage(locationPath, screens) {
  if (process.env.NODE_ENV !== 'production') {
    // console.log(`trackPage ${locationPath} - blocked in dev`);
    return;
  }
  const stats = window.__DATA__?.screens?.stats || {};
  const { trackPages = true } = stats;

  const isUserConnected = store?.user?._id;
  if (!isUserConnected) return;
  // Default appcraft events tracking, no await needed
  if (trackPages) {
    platformService.stats('VIEW_PAGE', {
      path: locationPath,
      url: window.location.href,
    });
  }

  const page = getCurrentPage(screens, locationPath);
  if (window.trackPage) {
    window.trackPage(page, { ...store, item: { slug: splitPath(locationPath)?.sub } });
  }
  if (!page) return; // Not sure what to track...

  if (window.__DATA__?.tagCommander?.trackPages) {
    tcTrackPage(locationPath, page);
  }
}
