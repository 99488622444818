import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { workshopProptypes } from '../../../propTypes';
import { checkUserAvailability } from '../../../utils/agendaUtils';
import Images from '../../../utils/Images';
import WorkshopActions from '../../../workshops/components/WorkshopTable/WorkshopActions';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import { getClassName } from '../../../workshops/utils';
import EntityLink from '../../EntityLink';
import SpeakerAvatars from '../../speakers/SpeakerAvatars';
import QuotaField from '../QuotaField';
import WorkshopDate from '../WorkshopDate';
import './VodCard.scss';

const VodCard = (props) => {
  const { workshop, showSpeakers, speakersConfig, actions, tagFields, ...rest } = props;
  const {
    _id,
    endDate,
    image,
    quota,
    shortDescription,
    speakers,
    startDate,
    title,
    usersCount,
    workshopDuration: duration,
    workshopStatus,
  } = workshop;
  const { isHighlighted } = rest || {};

  const hasImage = image && image.uri;
  const agenda = useSelector(userAgenda);
  const [showActions, setShowActions] = useState(false);
  const hasOverlappingEvent = useMemo(() => {
    const otherEvents = agenda.filter((evt) => evt._id !== _id);
    return !checkUserAvailability(otherEvents, workshop);
  }, [agenda, workshop, _id]);

  return (
    <div
      className={cx(
        'workshop--vod',
        getClassName(workshop),
        `session--${workshopStatus}`,
        hasOverlappingEvent && `session--has-overlapping-event`,
        { hidden: !isHighlighted },
      )}
    >
      <Card as={EntityLink} entity={workshop}>
        {hasImage && (
          <div className="card__image">
            <div className={cx('overlay', { hasActions: showActions })}>
              {actions?.length > 0 && (
                <>
                  <Icon
                    className="ellipsis"
                    name="ellipsis vertical"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowActions(!showActions);
                    }}
                  />
                  {showActions && <WorkshopActions actions={actions} workshop={workshop} />}
                </>
              )}
            </div>
            <div
              className="image-with-shadow"
              style={{
                backgroundImage: `url(${Images.maxWidth(image, 800)})`,
              }}
            />
            {duration && <div className="duration">{duration}</div>}
          </div>
        )}
        <Card.Content className="borderless">
          <Card.Description style={{ marginTop: 8, marginBottom: 8 }}>
            <WorkshopDate startDate={startDate} endDate={endDate} />
          </Card.Description>
          <QuotaField quota={quota} usersCount={usersCount} />
          <Card.Header style={{ fontSize: '1.2em', fontWeight: 'normal' }}>{title}</Card.Header>
          <Card.Description>{shortDescription}</Card.Description>
          <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
          {showSpeakers && <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />}
        </Card.Content>
      </Card>
    </div>
  );
};

VodCard.defaultProps = {
  actions: undefined,
  showSpeakers: false,
  speakersConfig: {},
  tagFields: [],
};

VodCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  showSpeakers: PropTypes.bool,
  speakersConfig: PropTypes.object,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default VodCard;
