import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';

const css = bem('TimezoneTabs');

function TimezoneTabs(props) {
  const { zones, onClick, activeItem } = props;
  if (!zones?.length) return null;

  const handleClickItem = (_e, { name }) => {
    localStorage.setItem('timezone', name);
    onClick(name);
  };
  return (
    <Menu
      style={{ marginTop: 0 }}
      className={css().toString()}
      pointing
      secondary
      fluid
      widths={zones.length}
    >
      {zones.map(({ key, value, label }) => (
        <Menu.Item key={key} name={value} active={activeItem === value} onClick={handleClickItem}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );
}

TimezoneTabs.defaultProps = {
  activeItem: undefined,
  zones: [],
};

TimezoneTabs.propTypes = {
  activeItem: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object),
};

export default TimezoneTabs;
