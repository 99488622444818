import PropTypes from 'prop-types';
import React from 'react';
import { Card, Grid, Header } from 'semantic-ui-react';
import { generateGroups, generateWorkshopStatus } from '../../utils/groupUtils';
import { WorkshopWithSessions } from '../../workshop-session/components/WorkshopGroup';
import ImageIcon from '../ImageIcon';
import ClickableWorkshopCard from './ClickableWorkshopCard';
import VodCard from './variants/VodCard';
import WorkshopCard3dx from './variants/WorkshopCard3dx';
import WorkshopOvhCard from './variants/WorkshopOvhCard';
import WorkshopCard3 from './WorkshopCard3';
import './WorkshopList.scss';
import WorkshopSessionsCard from './WorkshopSessionsCard';

const variants = {
  card: WorkshopCard3,
  clickable: ClickableWorkshopCard,
  '3ds': WorkshopCard3dx,
  ovh: WorkshopOvhCard,
  cardWithSessions: WorkshopSessionsCard,
  simpleSessions: WorkshopWithSessions,
  vod: VodCard,
};

function isWorkshopHighlited(filteredWorkshopIds, workshopId) {
  if (!filteredWorkshopIds || filteredWorkshopIds.length === 0) return true;
  return filteredWorkshopIds?.includes(workshopId);
}

const listPropTypes = {
  centered: PropTypes.bool,
  itemProps: PropTypes.object,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.any.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  minHeight: PropTypes.number,
};

const listDefaultProps = {
  itemProps: undefined,
  centered: false,
  filteredWorkshopIds: undefined,
  groups: [],
  minHeight: undefined,
};

const HorizontalLists = ({
  groups,
  centered,
  minHeight,
  filteredWorkshopIds,
  Component,
  itemProps,
}) => (
  <>
    {groups.map(({ id, items, label }) => (
      <div key={id} className={`workshops--group workshops--group--${id}`}>
        <Header as="h3">{label}</Header>
        <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
          {items.map((item) => (
            <Component
              key={item._id}
              workshop={item}
              minHeight={minHeight}
              isHighlighted={isWorkshopHighlited(filteredWorkshopIds, item._id)}
              {...itemProps}
            />
          ))}
        </Card.Group>
      </div>
    ))}
  </>
);

HorizontalLists.defaultProps = listDefaultProps;
HorizontalLists.propTypes = listPropTypes;

const VerticalLists = ({
  centered,
  Component,
  groups,
  filteredWorkshopIds,
  iconConfig,
  itemProps,
  minHeight,
}) => (
  <Grid className="workshops--container vertical" columns="equal" centered={centered}>
    {groups.map(({ id, items, label, image }) => (
      <Grid.Column key={id} className={`workshops--group workshops--group--${id}`}>
        <div className="category">
          <ImageIcon icon={image} {...iconConfig} />
          <Header as="h3">{label}</Header>
        </div>
        {items.map((item) => (
          <Component
            key={item._id}
            workshop={item}
            minHeight={minHeight}
            isHighlighted={isWorkshopHighlited(filteredWorkshopIds, item._id)}
            {...itemProps}
          />
        ))}
      </Grid.Column>
    ))}
  </Grid>
);
VerticalLists.defaultProps = { ...listDefaultProps, iconConfig: {}, minHeight: undefined };
VerticalLists.propTypes = {
  ...listPropTypes,
  iconConfig: PropTypes.object,
};

export function filterOptionsIfNeeded(groupByConfig, filters) {
  if (!filters) return groupByConfig;
  const { field, options } = groupByConfig;
  if (filters && filters[field] && options) {
    // Filter groups as well...
    return { ...groupByConfig, options: options.filter((opt) => opt.value === filters[field]) };
  }
  return groupByConfig;
}

const WorkshopList = ({
  centered,
  filteredWorkshopIds,
  filters,
  groupBy: groupByConfig,
  itemProps,
  template,
  workshopList,
}) => {
  const workshops = generateWorkshopStatus(workshopList);
  const { minHeight, type = 'card', variant } = template;
  const WorkshopComponent = variants[variant] || variants[type];

  if (groupByConfig) {
    const { isVertical } = groupByConfig;
    const groups = generateGroups(workshops, filterOptionsIfNeeded(groupByConfig, filters));
    const ListComponent = isVertical ? VerticalLists : HorizontalLists;
    const { iconConfig } = groupByConfig || {};
    return (
      <div className="items--group workshops--group">
        <ListComponent
          centered={centered}
          Component={WorkshopComponent}
          groups={groups}
          filteredWorkshopIds={filteredWorkshopIds}
          iconConfig={iconConfig}
          itemProps={itemProps}
          minHeight={minHeight}
        />
      </div>
    );
  }
  return (
    <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
      {workshops.map((item) => (
        <WorkshopComponent
          key={item._id}
          workshop={item}
          isHighlighted={isWorkshopHighlited(filteredWorkshopIds, item._id)}
          {...itemProps}
        />
      ))}
    </Card.Group>
  );
};

WorkshopList.defaultProps = {
  centered: undefined,
  filters: undefined,
  filteredWorkshopIds: undefined,
  groupBy: undefined,
  itemProps: {},
  template: 'card',
};
WorkshopList.propTypes = {
  workshopList: PropTypes.array.isRequired,
  centered: PropTypes.bool,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.object,
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  itemProps: PropTypes.object,
  template: PropTypes.oneOf(['card', 'clickable', '3ds']),
};

export default WorkshopList;
