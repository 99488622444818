/* eslint-disable class-methods-use-this */
import { getJson, postJson } from '../../utils/fetch';

class AuthService {
  constructor() {
    this.api = window.__DATA__.endpoints.platform;
  }

  async login(login, password, collection = 'participants', options = {}) {
    const { method, includesFirebase = true, validatedLegalNotice } = options;
    return postJson(`${this.api}/login`, {
      login,
      password,
      method,
      validatedLegalNotice,
      collection,
      includesFirebase,
    });
  }

  async renewFirebaseToken(token) {
    return getJson(`${this.api}/auth/renewFirebaseToken`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  }

  async forgottenPassword(login) {
    return postJson(`${this.api}/auth/forgottenPassword`, { login });
  }

  async sendOneTimePassword(login) {
    return postJson(`${this.api}/auth/sendOneTimePassword`, { login });
  }

  async resetPassword(
    token,
    password,
    collection = 'participants',
    { autoLogin = false, includesFirebase = true } = {},
  ) {
    return postJson(`${this.api}/auth/resetPassword`, {
      token,
      password,
      collection,
      autoLogin,
      includesFirebase,
    });
  }

  async autoLogin(token, collection = 'participants', { includesFirebase = true } = {}) {
    return postJson(`${this.api}/auth/autoLogin`, {
      token,
      collection,
      includesFirebase,
    });
  }

  async impersonate(token, options = {}) {
    const { collection = 'participants' } = options;
    return postJson(`${this.api}/auth/impersonate`, { token, collection });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async registerAccount(userData, collection) {
    return postJson(`${this.api}/auth/register/${collection || 'participants'}`, {
      user: userData,
    });
  }
}

export default new AuthService();
