import { useMount } from 'ahooks';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Header, Message } from 'semantic-ui-react';
import ResetPasswordFields from './components/ResetPasswordFields';
import { withConfig } from '../config/config.context';
import { withDesign } from '../config/design.context';
import useTranslations from '../hooks/useTranslations';
import store, { getParameterByName } from '../shared/Store';
import { getString } from '../utils';
import LoginLayout from './LoginLayout';
import { ensureConstraint, validateConstraints } from './utils/passwordUtils';

const translationPrefix = 'login';

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: false,
      token: getParameterByName('token'),
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ [name]: value, message: null, error: null });
  };

  // eslint-disable-next-line consistent-return
  handleResetPassword = async () => {
    const { password, token } = this.state;
    const { config } = this.props;
    const autoLogin = config?.screens?.login?.resetPassword?.autoLogin;
    const stayConnected = config?.screens?.login?.stayConnected;

    const res = await store.resetPassword(token, password, autoLogin, stayConnected);
    if (res.success) {
      this.setState({ success: true, error: null });
    } else {
      // FIXME: remove this if with check token validity before pressing reset password button
      this.setState({ error: getString(`${translationPrefix}.link-has-expired`) });
    }
  };

  render() {
    const { design, config } = this.props;
    const variant = config?.screens?.login?.variant;
    const { password, confirmPassword, error, success, token } = this.state;
    const passswordType = config?.screens?.login?.resetPassword?.type || 'simple';

    // check constraints
    const validatedConstraints = validateConstraints(
      password,
      confirmPassword,
      ensureConstraint(passswordType),
    );
    const isPasswordNotValid = !!find(validatedConstraints, { isValid: false });

    // check if exhibitor is already connected
    const currentStorage = localStorage.getItem(store.getLocalStorageKey());

    if (success || !token || (currentStorage && currentStorage !== '{}')) {
      // redirect to login screen
      return <Redirect to="/login" />;
    }

    const { primaryColor: color } = design;
    return (
      <LoginLayout screen="reset-password" variant={variant}>
        <Header as="h2" style={{ color }} textAlign="center">
          {getString(`${translationPrefix}.please-enter-new-password`)}
        </Header>
        <ResetPasswordFields
          password={password}
          confirmPassword={confirmPassword}
          constraints={validatedConstraints}
          onChange={this.handleChange}
        />
        <Button
          style={{ backgroundColor: color, color: 'white' }}
          fluid
          size="large"
          onClick={this.handleResetPassword}
          disabled={isPasswordNotValid}
        >
          {getString(`${translationPrefix}.reset-password`)}
        </Button>
        {error && (
          <Message
            className="error-message"
            error
            visible
            style={{
              textAlign: 'left',
              background: '#fff6f6',
              boxShadow: '0 0 0 1px #e0b4b4 inset',
              padding: '1em 1.5em',
            }}
            header={getString(`${translationPrefix}.error`)}
            content={error}
          />
        )}
      </LoginLayout>
    );
  }
}

ResetPasswordScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

const AutoLogin = (props) => {
  const { config } = props;
  const token = getParameterByName('token');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(token ? undefined : 'auto-login.error-missing-token');
  const { t } = useTranslations(translationPrefix);
  useMount(async () => {
    if (!token) return;

    const { stayConnected = true } = config?.screens?.login || {};
    const res = await store.autoLogin(token, stayConnected);
    if (res && res.success) {
      setSuccess(true);
    } else {
      setError('auto-login.error-invalid-token');
    }
  });

  const { layout } = config?.screens?.login || {};
  const variant = layout?.variant;

  const { design } = props;
  const { primaryColor: color } = design;

  if (success || !token) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout variant={variant} screen="auto-login">
      <Header as="h2" style={{ color }} textAlign="center">
        {t('auto-login.title')}
      </Header>
      {error && (
        <Message
          negative
          icon="warning circle"
          style={{ textAlign: 'left' }}
          header={t('error')}
          content={t(error)}
        />
      )}
      {!error && (
        <Message
          style={{ textAlign: 'left' }}
          icon={{ name: 'refresh', loading: true }}
          content={t('auto-login.description')}
        />
      )}
    </LoginLayout>
  );
};

export const AutoLoginScreen = withConfig(withDesign(AutoLogin));

const ResetOrActiveScreen = (props) => {
  const { config } = props;
  const { mode } = config?.screens?.login || {};
  if (mode === 'otp') {
    return <AutoLogin {...props} />;
  }
  return <ResetPasswordScreen {...props} />;
};

ResetOrActiveScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

export default withConfig(withDesign(ResetOrActiveScreen));
