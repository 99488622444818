import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO, isAfter, addMilliseconds } from 'date-fns';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { useScreenConfig } from '../../config/screens.context';
import { TrackingContext } from '../../Context';
import platformService from '../../core/services/platform.service';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import {
  showBookConflict,
  showBookConflictConfirm,
} from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { fetchRegistrations } from '../../store/actions';
import { getString } from '../../utils';
import { findOverlappingEvent } from '../../utils/agendaUtils';
import { sweetAlert, sweetConfirmAlert } from '../../utils/popupUtils';
import { useAutoRefresh } from '../../utils/hooks';
import { userAgenda } from '../../agenda/store/agenda.selectors';

const translationPrefix = 'workshops.workshop';

function showError(code) {
  let title = '';
  switch (code) {
    case 'E_UNAVAILABLE_USER':
      title = getString(`agenda.unavailable-user`);
      break;
    case 'E_WORKSHOP_FULL':
      title = getString(`${translationPrefix}.full-workshop`);
      break;
    default:
      title = getString(`${translationPrefix}.workshop-registration-error`);
  }

  return sweetAlert({ icon: 'error', title });
}

const defaultLockConfig = {
  enable: false,
  lockTimeOffset: 0,
};

export async function registerWorkshop(
  workshop,
  agenda,
  { trackEvent, setLoading, allowOverlappingSessions },
) {
  setLoading(true);
  try {
    const overlappingEvent = findOverlappingEvent(agenda, workshop);
    if (overlappingEvent && !allowOverlappingSessions) {
      await showBookConflict(overlappingEvent);
      return;
    }

    const userIsAvailableOrStillWantToBook =
      overlappingEvent === null ||
      overlappingEvent === false ||
      overlappingEvent === undefined ||
      (await showBookConflictConfirm(overlappingEvent));

    if (!userIsAvailableOrStillWantToBook) {
      return;
    }
    const res = await platformService.registerSession(workshop._id);
    if (res.success) {
      trackEvent(eventTags.WORKSHOP_REGISTER, {
        userId: store.user._id,
        workshop,
      });
      store.reduxStore.dispatch(fetchRegistrations());
      store.reduxStore.dispatch(fetchAgenda());
      toast(getString(`${translationPrefix}.successful-registration`));
    } else if (res.errors) {
      const { code } = res.errors[0];
      if (code === 'E_WORKSHOP_FULL') {
        showError(code);
        store.reduxStore.dispatch(fetchRegistrations());
      } else showError('ERROR');
    }
  } catch (error) {
    console.error(error);
    showError('ERROR');
  } finally {
    setLoading(false);
  }
}

export async function unregisterWorkshop(workshop, { setLoading, trackEvent }) {
  const confirm = await sweetConfirmAlert({
    title: getString(`${translationPrefix}.unregister-confirm`, {
      name: workshop.title,
    }),
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
  if (confirm) {
    trackEvent(eventTags.WORKSHOP_UNREGISTER, {
      userId: store.user._id,
      workshop,
    });
    if (setLoading) setLoading(true);
    await platformService.unregisterSession(workshop._id);
    store.reduxStore.dispatch(fetchRegistrations());
    store.reduxStore.dispatch(fetchAgenda());
    toast(getString(`${translationPrefix}.successful-cancel`, { name: workshop.title }));

    if (setLoading) setLoading(false);
  }
}

function isWorkshopEnded(now, endDate, lockConfig = {}) {
  const { enable = true, lockTimeOffset = 0 } = lockConfig;
  return enable && isAfter(addMilliseconds(now, -lockTimeOffset), parseISO(endDate));
}

export function useWorkshopRegistration(workshop = {}) {
  const agenda = useSelector((state) => userAgenda(state));
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useContext(TrackingContext);
  const { lockAfterEnd: lockConfig = defaultLockConfig, allowOverlappingSessions = false } =
    useScreenConfig('workshops')?.workshop || {};
  const now = useAutoRefresh(30000);
  const { endDate, usersCount, quota } = workshop;
  const ended = isWorkshopEnded(now, endDate, lockConfig);
  const isWorkshopFull = quota ? quota === usersCount : false;

  return {
    isWorkshopFull,
    isWorkshopEnded: ended,
    registerWorkshop: () =>
      registerWorkshop(workshop, agenda, {
        trackEvent,
        setLoading,
        allowOverlappingSessions,
      }),
    loading,
    unregisterWorkshop: () => unregisterWorkshop(workshop, { setLoading, trackEvent }),
  };
}
