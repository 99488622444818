/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { userArrayProptypes } from '../../../propTypes';
import { replaceValues } from '../../../utils/stringUtils';
import SpeakerModal from '../../speakers/SpeakerModal';

const TextVariant = ({ template, users, ...rest }) => {
  const [selectedUser, setSelectedUser] = useState(undefined);
  if (users.length === 0) return null;
  const { showModal = false, modalConfig = {} } = rest || {};
  const usersName = users.map((user) => ({ label: replaceValues(template, user), ...user }));
  if (showModal) {
    return (
      <div>
        {usersName.map((user, index) => {
          const { _id, label } = user;
          const comma = index !== users.length - 1 ? ', ' : '';
          return (
            <a
              basic
              key={_id}
              style={{ cursor: 'pointer' }}
              onClick={() => (showModal ? setSelectedUser(user) : undefined)}
            >{`${label}${comma}`}</a>
          );
        })}
        {selectedUser && (
          <SpeakerModal
            speaker={selectedUser}
            modalConfig={modalConfig}
            onClose={() => setSelectedUser(undefined)}
          />
        )}
      </div>
    );
  }
  return <div>{usersName.map((u) => u?.label).join(', ')}</div>;
};

TextVariant.defaultProps = {
  template: '{firstName} {lastName}',
  users: [],
};
TextVariant.propTypes = {
  template: PropTypes.string,
  users: userArrayProptypes,
};

export default TextVariant;
