import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useMe } from '../../profile/hooks';
import { getString } from '../../utils';
import { validatesCondition } from '../../utils/conditionUtils.ts';
import useLastSeen from '../hooks/useLastSeen';
import useLightbox from '../hooks/useLightbox';
import NewSocialWallPostModal from '../NewSocialWallPostModal';
import '../SocialWall.scss';
import SocialWallCard from '../SocialWallCard';
import { useSocialWallData } from '../useSocialWallData';
import UpdatableMasonic from './UpdatableMasonic';

const translationPrefix = 'social-wall';

function SocialWall({ masonryConfig, postCondition }) {
  const [isOpen, setIsOpen] = useState(false);
  const userProfile = useMe();
  const { columnWidth, columnGutter } = masonryConfig;
  const { messages, likes } = useSocialWallData();
  const { lightbox, openLightbox } = useLightbox(messages);
  const canPost = validatesCondition(postCondition, userProfile);

  useLastSeen(messages);

  return (
    <div className="SocialWall">
      {lightbox}
      {canPost && (
        <div style={{ textAlign: 'right' }}>
          <Button className="SocialWall--Button" onClick={() => setIsOpen(true)}>
            <Icon name="send" />
            {getString(`${translationPrefix}.add-message`)}
          </Button>
        </div>
      )}
      <UpdatableMasonic
        className="SocialWall--Masonry"
        items={messages}
        itemKey={(data) => (data ? data.id : undefined)}
        columnWidth={columnWidth}
        columnGutter={columnGutter}
        render={({ data: message }) => (
          <SocialWallCard post={message} likes={likes} onClick={() => openLightbox(message.id)} />
        )}
      />
      {canPost && isOpen && <NewSocialWallPostModal onClose={() => setIsOpen(false)} />}
    </div>
  );
}

SocialWall.defaultProps = {
  masonryConfig: {},
  postCondition: undefined,
};

SocialWall.propTypes = {
  masonryConfig: PropTypes.shape({ columnWidth: PropTypes.number, columnGutter: PropTypes.number }),
  postCondition: PropTypes.object,
};

export default SocialWall;
