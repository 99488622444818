import { format, parseISO } from 'date-fns';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { dateProptypes } from '../propTypes';
import { dateFNSLocale, forceTimezone, momentTz } from '../utils/date';

export const TimeDisplay = ({ startDate, endDate }) => {
  const dateOptions = { locale: dateFNSLocale() };
  if (forceTimezone()) {
    return (
      <p className="time-display">
        <span className="start-date">{momentTz(parseISO(startDate)).format('LT')}</span>
        <span className="end-date"> - {momentTz(parseISO(endDate)).format('LT')}</span>
        <span className="timezone">{momentTz(parseISO(startDate)).format(' z')}</span>
      </p>
    );
  }
  return (
    <p className="time-display">
      <span className="start-date">{format(parseISO(startDate), 'p', dateOptions)}</span>
      <span className="end-date"> - {format(parseISO(endDate), 'p', dateOptions)}</span>
      <span className="timezone">{format(parseISO(startDate), ' O', dateOptions)}</span>
    </p>
  );
};

TimeDisplay.defaultProps = {
  endDate: undefined,
  startDate: undefined,
};

TimeDisplay.propTypes = {
  endDate: dateProptypes,
  startDate: dateProptypes,
};

export const DateDisplay = ({ startDate }) => {
  const dateOptions = { locale: dateFNSLocale() };
  return (
    <p className="date-display" style={{ marginBottom: 0 }}>
      <Icon name="calendar outline alternate" />
      {format(parseISO(startDate), 'PPPP', dateOptions)}
    </p>
  );
};

DateDisplay.defaultProps = {
  startDate: undefined,
};

DateDisplay.propTypes = {
  startDate: dateProptypes,
};

export const DateTimeDisplay = ({ startDate, endDate }) => {
  if (!startDate) return null;
  return (
    <div className="date-time-display">
      <DateDisplay startDate={startDate} endDate={endDate} />
      {endDate && <TimeDisplay startDate={startDate} endDate={endDate} />}
    </div>
  );
};

DateTimeDisplay.defaultProps = {
  endDate: undefined,
  startDate: undefined,
};

DateTimeDisplay.propTypes = {
  endDate: dateProptypes,
  startDate: dateProptypes,
};
