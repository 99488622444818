import { isAfter, parseISO } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { GroupedUserSessions } from '../../agenda/blocks/UserAgendaBlock';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import useTranslations from '../../hooks/useTranslations';
import './UpcomingSessions.scss';

const upcomingAgendaSessions = (state) => {
  const formattedAgenda = userAgenda(state);
  const now = new Date();
  return formattedAgenda.filter(({ endDate }) => isAfter(parseISO(endDate), now));
};

const UpcomingSessions = ({ clickableSession }) => {
  const { t } = useTranslations('home');
  const sessions = useSelector(upcomingAgendaSessions);
  return (
    <Segment className="home__upcoming">
      <Header as="h3"> {t('upcoming-meetings')}</Header>
      <div className="home__upcoming__sessions">
        <GroupedUserSessions sessions={sessions} clickableSession={clickableSession} />
        <Link to="/profile" className="view-all">
          {t('view-all')} <Icon name="angle right" />
        </Link>
      </div>
    </Segment>
  );
};

UpcomingSessions.defaultProps = {
  clickableSession: true,
};

UpcomingSessions.propTypes = {
  clickableSession: PropTypes.bool,
};

export default UpcomingSessions;
