import cx from 'classnames';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, Feed, Image } from 'semantic-ui-react';
import Linkifier from 'react-linkifier';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import { dateFNSLocale } from '../../utils/date';
import { useAutoRefresh } from '../../utils/hooks';
import Images from '../../utils/Images';
import { sweetConfirmAlert } from '../../utils/popupUtils';
import './ChatFeed.scss';
import { getString } from '../../utils';

function formatLastName(name) {
  return name ? `${name.slice(0, 1)}.` : '';
}
function shortName(profile) {
  return `${profile?.firstName} ${formatLastName(profile?.lastName)}`;
}
function longName(profile) {
  return `${profile?.firstName} ${profile?.lastName}`;
}

function formatProfileName(profile) {
  return profile?.isSponsor ? longName(profile) : shortName(profile);
}

const ChatFeed = ({ feedHeader, messages, me, sponsorImage, onDelete }) => {
  useAutoRefresh(60 * 1000); // Refresh messages times...

  const removeMessage = useCallback(
    async (id) => {
      if (!onDelete) return;
      const response = await sweetConfirmAlert({
        title: getString('chat.delete-message'),
        confirmButtonText: getString(`alert.confirm`),
        cancelButtonText: getString(`alert.cancel`),
      });
      if (!response) return;
      onDelete(id);
    },
    [onDelete],
  );

  return (
    <Feed className="feed--chat Chat--Feed" key="Chat--Feed">
      <div className="Chat--PushDown" />
      {feedHeader && feedHeader.text && (
        <div className="Chat--HeaderMessage">{feedHeader.text}</div>
      )}
      {messages &&
        messages.map(({ text, emitter, profile, createdAt }) => {
          const isSelf = emitter === me;
          const avatar = (
            <Feed.Label>
              <Image src={avatarWithFallbackWhenMissing(profile?.thumbnail)} avatar size="tiny" />
            </Feed.Label>
          );
          return (
            <Feed.Event
              className={cx('Chat--Message', {
                'event--self': isSelf,
                'event--other': !isSelf,
                'event--sponsor': profile?.isSponsor,
              })}
            >
              {avatar}
              <Feed.Content>
                <Feed.Summary>
                  {profile?.isSponsor && sponsorImage && (
                    <img
                      className="image--sponsor"
                      alt="sponsor"
                      {...Images.srcSet.maxWidth(sponsorImage, 200)}
                    />
                  )}
                  {/* TODO : simplify once ovh ends name is for retro compatibility */}
                  <span>{profile?.name ? profile?.name : formatProfileName(profile)}</span>
                </Feed.Summary>
                {text.map(({ id, text: rawText }) => (
                  <Feed.Content key={`message-${id}`} className="Chat--Feed__TextContent">
                    <Linkifier target="_blank">
                      <p>{rawText} </p>
                      {isSelf && onDelete && (
                        <Button
                          circular
                          negative
                          compact
                          size="mini"
                          className="Chat--Feed__DeleteButton"
                          onClick={() => removeMessage(id)}
                          icon="delete"
                        />
                      )}
                    </Linkifier>
                  </Feed.Content>
                ))}
                <Feed.Meta>
                  <Feed.Date
                    content={formatDistanceToNow(createdAt, {
                      addSuffix: true,
                      locale: dateFNSLocale(),
                    })}
                  />
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>
          );
        })}
    </Feed>
  );
};

ChatFeed.defaultProps = {
  feedHeader: undefined,
  sponsorImage: undefined,
  onDelete: undefined,
};

ChatFeed.propTypes = {
  feedHeader: PropTypes.shape({
    text: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
      createdAt: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
      emitter: PropTypes.string.isRequired,
      profile: PropTypes.shape({
        isSponsor: PropTypes.bool,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        name: PropTypes.string, // TODO : retro compatibility should be deleted once ovh ends
        thumbnail: PropTypes.object,
      }),
    }),
  ).isRequired,
  me: PropTypes.string.isRequired,
  sponsorImage: PropTypes.shape({ uri: PropTypes.string }),
  onDelete: PropTypes.func,
};

export default ChatFeed;
