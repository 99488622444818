import keyBy from 'lodash/keyBy';
import { updateOrAppendItem } from '../../utils/arrayUtils.ts';
import { SET_ALL, SET_BY_ID } from './workshops.types';

const initialState = {
  workshops: [],
  workshopsById: {},
};

function ensureCollectionAndSlug(w) {
  return {
    ...w,
    kind: 'workshops',
    originalCollection: w.collection,
    slug: w.slug ? w.slug.trim() : w._id,
  };
}

export default function reduceWorkshops(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      const workshops = action.workshops.map(ensureCollectionAndSlug);
      return { workshops, workshopsById: keyBy(workshops, '_id') };
    }
    case SET_BY_ID: {
      // Update a given sponsor
      const workshops = updateOrAppendItem(
        state.workshops,
        ensureCollectionAndSlug(action.workshop),
      );
      return { workshops, workshopsById: keyBy(workshops, '_id') };
    }
    default:
      return state;
  }
}
