import React, { PureComponent } from 'react';
import partition from 'lodash/partition';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Icon, Sidebar, Menu, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import AppHeader, { handleChangeLanguage } from '../AppHeader';
import store from '../../shared/Store';
import './menu.scss';
import ExpiringNavLink from '../ExpiringNavLink';
import EntitySearchBar from '../SearchBar/EntitySearchBar';
import { bem } from '../../core/design/bem';
import ImageIcon from '../ImageIcon';
import { SecondaryMenuEntry } from './AppSecondaryMenu';
import CdnImage from '../CdnImage';

const css = bem('mobile-menu');

const MobileMenuEntry = ({ entry, onChange }) => {
  const { name, label, path, icon, pageId, type } = entry;
  return (
    <Menu.Item
      className={css('item', { pageId: name || pageId, type }).toString()}
      as={path ? ExpiringNavLink : undefined}
      to={path}
      exact
      activeClassName="active"
      onClick={onChange}
      style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
    >
      <ImageIcon
        icon={icon}
        style={{
          height: 28,
        }}
        maxWidth={56}
      />
      {label}
    </Menu.Item>
  );
};

MobileMenuEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
class MenuMobile extends PureComponent {
  state = {
    isVisible: false,
  };

  handleDisconnect = async () => {
    await store.disconnect();
    this.forceUpdate();
  };

  toggleMenuBars = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  handleChange = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { menu, language, languageOptions, design } = this.props;
    const { logo, logoProps, menu: menuConfig } = design;
    const showLogo = get(menuConfig, ['mobile', 'showLogo'], false);

    if (!store.isLoggedIn()) {
      // Handle disconnect
      return <Redirect to="/login" />;
    }
    const [main, secondary] = partition(menu, ({ menuType }) => menuType === 'main');
    const { isVisible } = this.state;
    return (
      <div className="sidebar--menu">
        <div className="sidebar--menu__header">
          <Icon
            name="bars"
            style={{ fontSize: 20, marginTop: 8, marginLeft: 4, marginRight: 8 }}
            onClick={this.toggleMenuBars}
          />
          <AppHeader />
          <Icon
            name="power off"
            style={{ fontSize: 20, margin: '8px 0px 0px 8px' }}
            onClick={this.handleDisconnect}
          />
        </div>
        <EntitySearchBar className="sidebar--menu__search--bar" />
        <Sidebar
          className="sidebar--menu__container"
          animation="overlay"
          onHide={() => {
            this.setState({ isVisible: false });
          }}
          visible={isVisible}
          width="thin"
        >
          {showLogo && (
            <div className="sidebar--menu__container--header">
              <Icon
                name="times"
                style={{ position: 'absolute', left: 10, right: 10 }}
                onClick={() => this.setState({ isVisible: false })}
              />
              <CdnImage
                as="img"
                className="logo--main"
                src={logo}
                maxHeight={60}
                alt="event logo main"
                {...logoProps}
              />
            </div>
          )}
          <Menu className={css('main').toString()} vertical icon="labeled">
            {main.map((entry) => (
              <MobileMenuEntry key={entry.name} entry={entry} onChange={this.handleChange} />
            ))}
          </Menu>
          {secondary?.length > 0 && (
            <Menu
              className={css('secondary').toString()}
              vertical
              icon="labeled"
              style={{ width: '100%', margin: 0 }}
            >
              {secondary.map((entry) => (
                <SecondaryMenuEntry key={entry.name} entry={entry} onChange={this.handleChange} />
              ))}
            </Menu>
          )}
          {languageOptions && languageOptions.length > 1 && (
            <div className="dropdown--container language">
              <Dropdown
                item
                className="dropdown--languages"
                placeholder="Language"
                compact
                selection
                options={languageOptions}
                value={language}
                onChange={handleChangeLanguage}
              />
            </div>
          )}
        </Sidebar>
      </div>
    );
  }
}

MenuMobile.defaultProps = {
  language: 'fr',
  languageOptions: undefined,
  menu: [],
};

MenuMobile.propTypes = {
  design: PropTypes.object.isRequired,
  language: PropTypes.string,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      color: PropTypes.string,
      translations: PropTypes.object,
    }),
  ),
};

export default MenuMobile;
