import React from 'react';
import PropTypes from 'prop-types';
import CdnImage from '../CdnImage';

function ImageBlock(props) {
  const { image, title, style, ...rest } = props;
  return (
    <CdnImage
      as="img"
      src={image}
      style={{ maxWidth: '100%', ...style }}
      alt={title || 'img-block'}
      {...rest}
    />
  );
}

ImageBlock.defaultProps = {
  image: undefined,
  style: {},
  title: undefined,
};

ImageBlock.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  title: PropTypes.string,
};

export default ImageBlock;
