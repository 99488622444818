import throttle from 'lodash/throttle';
// eslint-disable-next-line import/no-cycle
import workshopSessionService from '../services/workshop-session.service';
import { SET } from './workshopSessions.types';

export function setWorkshopSessions(workshopSessions) {
  return {
    type: SET,
    workshopSessions,
  };
}

const throttledDispatch = throttle(
  async (dispatch) => {
    const workshopSessions = await workshopSessionService.fetchEventWorkshopSessions();
    dispatch(setWorkshopSessions(workshopSessions));
  },
  2000,
  {
    leading: true,
    trailing: true,
  },
);

export function throttledFetchWorkshopSessions() {
  return throttledDispatch;
}

export function fetchWorkshopSessions() {
  return async (dispatch) => {
    const workshopSessions = await workshopSessionService.fetchEventWorkshopSessions();
    if (workshopSessions) {
      dispatch(setWorkshopSessions(workshopSessions));
    }
  };
}
