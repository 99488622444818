/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Form } from 'semantic-ui-react';

const FieldInput = ({ field, label, value, onChange, editable, error }) => {
  const { key, type = 'string' } = field;

  if (!editable && type === 'array') return <>{value.join(', ')}</>;
  if (!editable) return <>{label}</>;

  switch (type) {
    case 'bool': {
      return (
        <Form.Checkbox
          toggle
          checked={value || false}
          onChange={(_, { checked }) => onChange({ [key]: checked })}
        />
      );
    }
    case 'string':
    default: {
      return (
        <Form.Input
          value={value || ''}
          fluid
          error={error}
          onChange={(_, { value: v }) => onChange({ [key]: v })}
        />
      );
    }
  }
};

export default FieldInput;
