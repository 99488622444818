import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { eventTags } from '../../../core/trackers/events';
import { workshopProptypes } from '../../../propTypes';
import { useWorkshopRegistration } from '../../utils/workshopRegistrationUtils';

function getActionOptions(action, registration) {
  const { key, label, icon } = action;
  switch (key) {
    case 'save': {
      if (!registration) return { label: 'Save', icon: 'star outline' };
      return { label: 'Unsave', icon: 'star' };
    }
    case 'replay':
    case 'share':
    default:
      return { icon, label };
  }
}

const ActionButton = ({ action, onClick }) => {
  const { icon, label, style, size } = action;
  return (
    <Button
      size={size}
      className="action"
      onClick={(e) => onClick(e, action)}
      labelPosition="right"
      style={style}
      icon
    >
      {label}
      <Icon name={icon} style={{ backgroundColor: 'transparent' }} />
    </Button>
  );
};

ActionButton.propTypes = {
  action: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const WorkshopActions = ({ workshop, actions }) => {
  const { trackEvent } = useTracking();
  const agenda = useSelector(userAgenda);
  const registration = agenda.find((a) => a._id === workshop._id);
  const { registerWorkshop, unregisterWorkshop } = useWorkshopRegistration(workshop);
  if (!actions || actions.length === 0) return null;
  const handleAction = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    switch (action.type) {
      case 'save': {
        if (!registration) {
          registerWorkshop();
        } else unregisterWorkshop();
        return action.type;
      }

      case 'action':
      case 'replay':
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: workshop, action });
        return action.type;
      default:
        return noop;
    }
  };
  return (
    <div className="actions">
      {actions.map((action) => {
        const { key } = action;

        return (
          <ActionButton
            key={key}
            action={{ ...action, ...getActionOptions(action, registration) }}
            onClick={handleAction}
          />
        );
      })}
    </div>
  );
};

WorkshopActions.defaultProps = {
  actions: [],
};

WorkshopActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopActions;
