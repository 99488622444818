/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// import React from 'react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Embed } from 'semantic-ui-react';
import { useTracking } from '../Context';
import { eventTags } from '../core/trackers/events';
import { useMe } from '../profile/hooks';
import store from '../shared/Store';
import Images, { cloudimg } from '../utils/Images';
import { replaceUriValues } from '../utils/stringUtils';
// import Iframe from 'react-iframe-click/src/index.tsx';
import { parseVideo } from '../utils/videoUtils';
import './VideoEmbed.scss';

export function EmbedWithIframeListener({ onInferredClick, iframe, ...props }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        iframeRef.current === document.activeElement &&
        onInferredClick
      ) {
        onInferredClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <Embed {...props} iframe={{ ...iframe, ref: iframeRef }} />;
}

EmbedWithIframeListener.defaultProps = {
  iframe: undefined,
  onInferredClick: undefined,
};

EmbedWithIframeListener.propTypes = {
  iframe: PropTypes.object,
  onInferredClick: PropTypes.func,
};

const VideoEmbed = ({ url, id, allowFullScreen, autoPlay, videoProps, ...props }) => {
  const me = useMe();
  const { trackEvent } = useTracking();
  const { item, video } = props;
  // eslint-disable-next-line no-extra-boolean-cast
  const videoTag = item ? eventTags.ITEM_VIDEO_VIEW : eventTags.VIDEO_VIEW;
  const videoTitle = video?.title || video?.originalFilename || '';

  const handleVideoClick = () => trackEvent(videoTag, { item, video, videoUrl: url, videoTitle });

  // Check video type...
  if (!url && !id) return null; // Nothing to show

  if (!url && id) {
    // Fallback to default embed mode
    return <EmbedWithIframeListener id={id} {...props} onInferredClick={handleVideoClick} />;
  }

  const { type, id: videoId, url: embedUrl } = parseVideo(url);
  const customUrl = replaceUriValues(embedUrl, { ...me, userId: store?.user?._id });
  switch (type) {
    case 'youtube': {
      return (
        <EmbedWithIframeListener
          autoplay={autoPlay}
          {...props}
          id={videoId}
          source="youtube"
          onInferredClick={handleVideoClick}
        />
      );
    }
    case 'video': {
      const { placeholder } = props;
      return (
        <div className="ui active embed">
          <div className="embed">
            <video
              autoPlay={autoPlay}
              allowFullScreen={allowFullScreen}
              controls
              poster={cloudimg(placeholder, { w: 1920, h: 1080, func: 'crop' })}
              {...videoProps}
              onPlay={handleVideoClick}
            >
              <source src={customUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      );
    }
    case 'meeting': {
      const { iframe, ...otherProps } = props;
      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            ...iframe,
            allow: 'fullscreen;camera;microphone;display-capture',
          }}
          {...otherProps}
          url={customUrl}
          onInferredClick={handleVideoClick}
        />
      );
    }

    default: {
      const { iframe, ...otherProps } = props;
      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            allow: 'fullscreen;camera;microphone;display-capture',
            ...iframe,
          }}
          {...otherProps}
          url={customUrl}
          onInferredClick={handleVideoClick}
        />
      );
    }
  }
};

VideoEmbed.defaultProps = {
  allowFullScreen: true,
  autoPlay: false,
  id: undefined,
  url: undefined,
};

VideoEmbed.propTypes = {
  allowFullScreen: PropTypes.bool,
  autoPlay: PropTypes.bool,
  id: PropTypes.string,
  url: PropTypes.string,
};

export default VideoEmbed;
