/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { Button, Header, Label, Modal } from 'semantic-ui-react';

import get from 'lodash/get';
import noop from 'lodash/noop';

import WorkshopActions from './WorkshopActions';
import './WorkshopModal.scss';

import CdnImage from '../../../components/CdnImage';
import HtmlTemplate from '../../../components/HtmlTemplate';
import SpeakerAvatars from '../../../components/speakers/SpeakerAvatars';
import { bem } from '../../../core/design/bem';
import { workshopArrayProptypes } from '../../../propTypes';
import Images from '../../../utils/Images';
import { useEntityTags } from '../../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';

const translationPrefix = 'workshops.workshop.modal';
const css = bem('WorkshopModal');

export const EntityTags = ({ entity, type, tagFields }) => {
  const tags = useEntityTags(entity, type, tagFields);
  if (!tags || !tags.length) return null;
  return (
    <div className="tags">
      {tags.map((tag) => (
        <Label className="tags--tag" key={tag}>
          {tag}
        </Label>
      ))}
    </div>
  );
};

EntityTags.defaultProps = {
  tagFields: [],
};

EntityTags.propTypes = {
  entity: PropTypes.object.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
};

const FooterButton = ({ type, icon, onAction, disabled }) => {
  const { t } = useTranslation();
  const label = t(`${translationPrefix}.${type}`);
  const isNext = type === 'next';
  return (
    <div className={cx(type, { disabled })} onClick={() => onAction(type)}>
      {isNext && label}
      <Button
        circular
        icon={icon}
        style={{ marginLeft: isNext ? 10 : 0, marginRight: isNext ? 0 : 10 }}
        disabled={disabled}
      />
      {!isNext && label}
    </div>
  );
};

FooterButton.defaultProps = {
  disabled: false,
};

FooterButton.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

function WorkshopModal(props) {
  const { workshopId, onClose, itemProps, onChange, workshops } = props;
  const { showSpeakers = false, speakersConfig, tagFields = [], actions = [] } = itemProps;
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const isDesktop = matches.desktop;
  const workshop = workshops.find(
    ({ _id, workshopId: sessionWorkshopId }) =>
      sessionWorkshopId === workshopId || _id === workshopId,
  );
  if (!workshop) return null;
  const { title, image, startDate, endDate, description, speakers } = workshop;
  const hasImage = Images.exists(image);
  const index = workshops.findIndex(
    ({ _id, workshopId: sessionWorkshopId }) =>
      sessionWorkshopId === workshopId || _id === workshopId,
  );

  const handleAction = (action) => {
    switch (action) {
      case 'next':
      case 'previous': {
        const newIndex = action === 'previous' ? index - 1 : index + 1;
        const nextSelectedWorkshopId = get(workshops?.[newIndex], '_id');
        if (!nextSelectedWorkshopId) return null;
        return onChange(nextSelectedWorkshopId);
      }
      case 'action':
      case 'save':
      case 'replay':
        return action;
      default:
        return noop;
    }
  };

  return (
    <Modal className={css().toString()} open onClose={onClose} closeIcon>
      {startDate && (
        <Modal.Header as="h3" className={css('header').toString()}>
          {t(`${translationPrefix}.date-time`, { startDate, endDate })}
        </Modal.Header>
      )}
      <Modal.Content className={css('content').toString()} style={{ padding: 0 }}>
        {hasImage && (
          <div className="image" style={{ flexShrink: 0 }}>
            <CdnImage src={image} />
          </div>
        )}
        <div className="container" style={{ padding: '20px 40px' }}>
          <Header as="h2" className="title">
            {title}
          </Header>
          {description && <HtmlTemplate className="description" template={description} />}
          {showSpeakers && speakers?.length > 0 && (
            <div className="speakers">
              {t(`${translationPrefix}.with`)}
              <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
            </div>
          )}
          <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
          <div className="footer">
            {!isDesktop && <WorkshopActions actions={actions} workshop={workshop} />}
            <div className="controls">
              <FooterButton
                type="previous"
                onAction={handleAction}
                icon="chevron left"
                disabled={index === 0}
              />
              {isDesktop && <WorkshopActions actions={actions} workshop={workshop} />}
              <FooterButton
                type="next"
                onAction={handleAction}
                icon="chevron right"
                disabled={index === workshops.length - 1}
              />
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

WorkshopModal.defaultProps = {
  itemProps: {},
  onChange: undefined,
  workshopId: undefined,
  workshops: [],
};

WorkshopModal.propTypes = {
  itemProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  workshopId: PropTypes.string,
  workshops: workshopArrayProptypes,
};

export default WorkshopModal;
