import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { useComponentOptions } from '../../config/design.context';
import { SHARE_SITES, sites } from './enums';
import { buildShareUrl } from './utils';

export const defaultAvailableCalendar = [SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK, SHARE_SITES.ICAL];

const translationPrefix = 'workshops.workshop';

const CalendarItem = ({
  as: Component = 'div',
  calendarType,
  event,
  children,
  onAdd,
  ...props
}) => {
  const url = buildShareUrl(event, calendarType);
  const handleCalendarButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onAdd) {
      onAdd({ calendarType, event });
    }

    if (url.startsWith('BEGIN')) {
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
      FileSaver.saveAs(blob, 'calendar.ics');
    } else {
      // Mobile support
      window.open(url, '_blank');
    }
  };
  return (
    <Component {...props} href={url} onClick={handleCalendarButtonClick}>
      {children}
    </Component>
  );
};

CalendarItem.propTypes = {};

const AddToCalendar = ({ event, onAdd, onOpen, iconOnly, ...props }) => {
  const { availableCalendar = defaultAvailableCalendar } = useComponentOptions('AddToCalendar');
  const { t } = useTranslation();

  if (availableCalendar.length === 1) {
    if (iconOnly) {
      return (
        <CalendarItem
          as={Button}
          className="icon button--calendar"
          content={
            <Icon.Group size="large">
              <Icon name="calendar outline" />
              <Icon corner="bottom right" name="add" />
            </Icon.Group>
          }
          onAdd={onAdd}
          calendarType={availableCalendar[0]}
          event={event}
          style={{ fontSize: 12 }}
          title={t(`${translationPrefix}.add-to-calendar`)}
          {...props}
        />
      );
    }
    return (
      <CalendarItem
        as={Button}
        className="icon button--calendar"
        primary
        fluid
        labeled
        labelPosition="left"
        icon="calendar alternate outline"
        content={t(`${translationPrefix}.add-to-calendar`)}
        onAdd={onAdd}
        calendarType={availableCalendar[0]}
        event={event}
        style={{ fontSize: 12 }}
        {...props}
      />
    );
  }

  return (
    <Dropdown
      className="icon primary WorkshopAddToCalendarBlock--Dropdown"
      icon="calendar alternate outline"
      button
      floating
      labeled
      compact
      onOpen={onOpen}
      text={t(`${translationPrefix}.add-to-calendar`)}
    >
      <Dropdown.Menu>
        {availableCalendar.map((calendar) => {
          const { label, icon } = sites[calendar] || {};
          return (
            <CalendarItem
              key={calendar}
              as={Dropdown.Item}
              onAdd={onAdd}
              text={label ?? calendar}
              value={calendar}
              image={{ src: icon }}
              event={event}
              calendarType={calendar}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

AddToCalendar.defaultProps = {
  iconOnly: false,
  onOpen: undefined,
};

AddToCalendar.propTypes = {
  event: PropTypes.object.isRequired,
  iconOnly: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

export default AddToCalendar;
