import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { getString } from '../../utils';
import store from '../../shared/Store';

const { endpoints } = window.__DATA__;

function getRoomLink(event) {
  const { type } = event;
  const { eventId, collection, _id, workshopId, sessionId } = event;
  switch (type) {
    case 'session':
      if (eventId === '8oyqWr7EcPXtT5') {
        return `${endpoints.meetings}/ws/workshops/${workshopId}/s/${sessionId || _id}/u/${
          store.userId
        }`;
      }
      return `${endpoints.meetings}/w/c/${collection}/${sessionId || _id}/u/${store.userId}`;
    case 'workshop':
      return `${endpoints.meetings}/w/c/${collection}/${_id}/u/${store.userId}`;
    case 'appointment':
      return `${endpoints.meetings}/a/c/${collection}/${_id}/u/${store.userId}`;
    default:
      return null;
  }
}

const ClickmeetingAccessButton = ({ event, disabled }) => {
  if (!event) return null;
  const link = getRoomLink(event);
  return (
    <Button
      primary
      size="small"
      icon
      target="_blank"
      labelPosition="right"
      href={disabled ? '#' : link}
      className="access-button"
      disabled={disabled}
    >
      <Icon name="arrow circle right" />
      {getString('appointments.access-room')}
    </Button>
  );
};

ClickmeetingAccessButton.defaultProps = {
  event: undefined,
  disabled: false,
};

ClickmeetingAccessButton.propTypes = {
  event: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ClickmeetingAccessButton;
