import React from 'react';
import { Card } from 'semantic-ui-react';
import CdnImage from '../../../CdnImage';
import ExpiringNavLink from '../../../ExpiringNavLink';
import { Image } from '../../types';
import { ensureTarget } from '../utils';

type CTACardProps = {
  title?: string;
  to?: string;
  subtitle?: string;
  imageProps?: Record<string, string>;
  image?: Image;
  target?: string;
};

const CTACard = ({ to, target, image, title, subtitle, imageProps }: CTACardProps): JSX.Element => (
  <Card as={to ? ExpiringNavLink : undefined} to={to} target={ensureTarget(target, to)} fluid>
    <CdnImage src={image} as="img" maxWidth={500} {...imageProps} />
    <Card.Content>
      <Card.Header>{title}</Card.Header>
      <Card.Description>{subtitle}</Card.Description>
    </Card.Content>
  </Card>
);

CTACard.defaultProps = {
  to: undefined,
  title: '',
  subtitle: undefined,
  image: undefined,
  imageProps: {},
  target: undefined,
};

export default CTACard;
