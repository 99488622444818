import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';
import CdnImage from '../components/CdnImage';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { HorizontalMenu } from '../Layouts/Default/components/HorizontalMenu';
import VerticalMenu from '../Layouts/Default/components/VerticalMenu';
import VerticalMenuHeader from '../Layouts/Default/components/VerticalMenu/components/VerticalMenuHeader';
import { imageProptypes } from '../propTypes';
import Styles from '../Styles';
import './CmsPage.scss';
import { CmsBlocks } from './CmsScreen';
import { useIsPreview } from '../config/preview.context';

const headerProps = {
  backgroundColor: PropTypes.string,
  logo: imageProptypes,
};

const CmsPageHeader = ({ logo, backgroundColor }) => {
  return (
    <div className="header--fixed" style={{ backgroundColor }}>
      <div className="container">
        {logo && <CdnImage className="logo" maxHeight={100} src={logo} as="img" />}
      </div>
    </div>
  );
};

CmsPageHeader.defaultProps = {
  logo: undefined,
  backgroundColor: undefined,
};
CmsPageHeader.propTypes = headerProps;

export const CmsPageLayout = ({
  className,
  header,
  cmsHeader,
  pageFooter,
  pageHeader,
  designOverride,
  children,
}) => {
  const design = useDesignConfig();
  const { menu } = useConfig();
  const isPreview = useIsPreview();
  const hasHeader = !!header;
  const background = designOverride.background || design.background; // page design
  const { color: backgroundColor, image: backgroundImage } = background || {};

  const isVerticalMenu = design?.menu?.wrapper?.variant === 'leftSidebar';
  return (
    <div
      className={cx(className ? className.toString() : undefined, {
        headered: hasHeader,
        'page--verticalMenu': isVerticalMenu,
      })}
    >
      {!isEmpty(designOverride) && <Styles designOverride={designOverride} />}
      {isVerticalMenu && !isPreview && menu && <VerticalMenu menu={menu} />}
      <PageBackground
        {...background}
        image={backgroundImage || (!backgroundColor && design.homeBackground)}
      />
      {header && <CmsPageHeader {...header} />}
      <div className="page--cms-container">
        {!isVerticalMenu && !isPreview && (
          <div className="menu-container" style={{ paddingBottom: 10 }}>
            {menu && <HorizontalMenu menu={menu} />}
          </div>
        )}
        {/** Deprecated => use instead pageHeader */}
        {cmsHeader && <CmsBlocks blocks={cmsHeader?.blocks} />}
        {pageHeader && <CmsBlocks blocks={pageHeader?.blocks} />}
        <div className="content">
          {isVerticalMenu && <VerticalMenuHeader design={design} />}
          {children}
        </div>
        {pageFooter && (
          <div className="page--cms-footer">
            <CmsBlocks blocks={pageFooter?.blocks} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

CmsPageLayout.defaultProps = {
  className: '',
  cmsHeader: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
  designOverride: {},
  header: undefined,
};

CmsPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cmsHeader: PropTypes.object,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  designOverride: PropTypes.object,
  header: PropTypes.object,
};

function CmsPage(props) {
  const { blocks, header, design, id, pageId, pageHeader, pageFooter, ...rest } = props;
  return (
    <CmsPageLayout
      className={cx('page', 'page--cms', id, pageId)}
      header={header}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <CmsBlocks blocks={blocks} {...rest} />
      </Container>
    </CmsPageLayout>
  );
}

CmsPage.defaultProps = {
  blocks: [],
  pageFooter: undefined,
  pageHeader: undefined,
  design: {},
  header: {},
  id: '',
};

CmsPage.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  design: PropTypes.object,
  header: PropTypes.object,
  id: PropTypes.string,
  pageId: PropTypes.string.isRequired,
};

export default CmsPage;
