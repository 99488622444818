import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import CdnImage from '../../../../../components/CdnImage';
import { SecondaryMenuEntry } from '../../../../../components/menu/AppSecondaryMenu';
import { useConfig } from '../../../../../config/config.context';

const RightMenu = ({ menu }) => {
  const { entries, config } = menu;

  if (!entries) return null;
  return (
    <div className="clearfix">
      <Menu secondary style={{ float: 'right', marginTop: '1em' }}>
        {entries.map((entry) => (
          <SecondaryMenuEntry key={entry._id} entry={entry} config={config} />
        ))}
      </Menu>
    </div>
  );
};

const VerticalMenuHeader = ({ design }) => {
  const { banner } = design;
  const { lang, languages, menus } = useConfig();

  if (!banner) return null;
  return (
    <>
      {menus?.secondary && <RightMenu menu={menus?.secondary} lang={lang} languages={languages} />}
      <Container className="page__header">
        <CdnImage src={banner} maxWidth={800} />
      </Container>
    </>
  );
};

export default VerticalMenuHeader;
