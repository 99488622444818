import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import { getString } from '../../utils';
import VideoEmbed from '../../components/VideoEmbed';
import BlockContainer from '../../components/BlockContainer';
import { eventTags } from '../../core/trackers/events';
import { useWorkshopTracker } from '../../utils/hooks';
import { containerTypePropTypes } from '../../propTypes';

const translationPrefix = 'workshops.workshop';

export const Replay = ({ item, replay, ...props }) => {
  const onInferredClick = useWorkshopTracker(eventTags.REPLAY_VIDEO_LAUNCHED, item);

  return <VideoEmbed onInferredClick={() => onInferredClick(replay)} {...props} />;
};

const WorkshopReplayBlock = ({ item, title: headerTitle, field, videoProps, containerType }) => {
  const videos = item[field] || [];

  if (!videos || videos.length === 0) return null;

  return (
    <BlockContainer
      className="block--video"
      type={containerType}
      header={headerTitle || getString(`${translationPrefix}.replay`)}
    >
      {videos.map((replay) => {
        const { _id, title, value, description, ...video } = replay;
        const { uri } = value;
        if (!uri) return null;
        return (
          <div key={_id} style={{ marginBottom: 10 }}>
            {title && (
              <Header as="h4" style={{ marginBottom: 0 }}>
                {title}
              </Header>
            )}
            {description && <div style={{ marginBottom: 8 }}>{description}</div>}
            <Replay
              icon="play"
              defaultActive
              replay={replay}
              item={item}
              {...videoProps}
              {...video}
              url={uri}
            />
          </div>
        );
      })}
    </BlockContainer>
  );
};

WorkshopReplayBlock.defaultProps = {
  containerType: 'segment',
  field: 'replays',
  title: '',
  videoProps: {},
};

WorkshopReplayBlock.propTypes = {
  containerType: containerTypePropTypes,
  field: PropTypes.string,
  item: PropTypes.object.isRequired,
  title: PropTypes.string,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

export default WorkshopReplayBlock;
