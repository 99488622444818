import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';

import { userProptypes } from '../../../propTypes';
import WorkshopsMatchingBlock from '../../workshops/WorkshopsMatchingBlock';
import SpeakerHeader from '../SpeakerHeader';
import './SpeakerModal.scss';

const css = bem('SpeakerModal');

const SpeakerModal = (props) => {
  const { speaker, onClose, modalConfig, variant = modalConfig.variant, ...rest } = props;
  const { showHeader = false, workshopConfig = {} } = modalConfig;
  const { showSessions, template } = workshopConfig;

  const { t } = useTranslation();
  return (
    <Modal
      size="small"
      className={css({ variant, visibleHeader: showHeader }).toString()}
      open
      closeIcon
      onClose={onClose}
    >
      {showHeader && (
        <Modal.Header className={css({ header: true }).toString()}>
          {t('speakers.modal.header', { ...speaker })}
        </Modal.Header>
      )}
      <Modal.Content scrolling>
        <SpeakerHeader
          {...rest}
          speaker={{ ...speaker, description: speaker?.fullDescription || speaker?.description }}
          variant={variant}
        />
        {showSessions && (
          <WorkshopsMatchingBlock
            field="speakers"
            value={speaker._id}
            template={template}
            itemProps={workshopConfig}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

SpeakerModal.defaultProps = {
  variant: undefined,
  modalConfig: {},
};

SpeakerModal.propTypes = {
  modalConfig: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  speaker: PropTypes.shape(userProptypes).isRequired,
  variant: PropTypes.string,
};

export default SpeakerModal;
