/* eslint-disable operator-linebreak */
import { differenceInHours, format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddToCalendar from '../../components/AddToCalendar';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
// import { useUser } from '../../core/users/users.hooks';
import store from '../../shared/Store';
import { useCalendarEventLink, useEntityUrl } from '../../utils/hooks';
import { workshopPropType } from '../utils';
import './WorkshopAddToCalendarBlock.scss';

const dateFormatForCalendar = `yyyyMMdd'T'HHmmss`;

export const AddWorkshopToCalendar = ({ workshop, ...props }) => {
  const url = useCalendarEventLink(workshop, true);
  const startAt = parseISO(workshop.startDate);
  const endAt = parseISO(workshop.endDate);
  const { trackEvent } = useContext(TrackingContext);

  const event = {
    description: `${workshop.shortDescription || ''}. \n${url}`,
    duration: differenceInHours(endAt, startAt),
    endDatetime: format(endAt, dateFormatForCalendar),
    startDatetime: format(startAt, dateFormatForCalendar),
    startDate: workshop.startDate,
    endDate: workshop.endDate,
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
    title: workshop.title,
    url,
  };

  function onAdd({ calendarType }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.user._id,
      item: workshop,
      kind: 'workshops',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.user._id,
      item: workshop,
      kind: 'workshops',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} />;
};

AddWorkshopToCalendar.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const WorkshopAddToCalendarBlock = memo(({ workshop }) => (
  <div className="WorkshopAddToCalendarBlock--Container">
    <AddWorkshopToCalendar workshop={workshop} />
  </div>
));

WorkshopAddToCalendarBlock.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const AddAppointmentToCalendar = ({ appointment, sponsor, ...props }) => {
  const { t } = useTranslation();
  const startAt = parseISO(appointment.startDate);
  const endAt = parseISO(appointment.endDate);
  const url = useEntityUrl({ kind: 'users', ...sponsor }, true);
  const { trackEvent } = useContext(TrackingContext);

  const event = {
    title: t('appointments.slots.meeting-with', {
      name: sponsor.name,
      entity: sponsor,
      appointment,
    }),
    description: `${sponsor?.shortDescription || ''}. \n${url}`,
    duration: differenceInHours(endAt, startAt),
    endDate: appointment.endDate,
    endDatetime: format(endAt, dateFormatForCalendar),
    startDate: appointment.startDate,
    startDatetime: format(startAt, dateFormatForCalendar),
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
  };

  function onAdd({ calendarType }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.user._id,
      item: appointment,
      kind: 'appointments',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.user._id,
      item: appointment,
      kind: 'appointments',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} />;
};

AddAppointmentToCalendar.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};

export const AppointmentAddToCalendarBlock = memo(({ appointment, sponsor, ...props }) => (
  <div className="WorkshopAddToCalendarBlock--Container">
    <AddAppointmentToCalendar appointment={appointment} sponsor={sponsor} {...props} />
  </div>
));

AppointmentAddToCalendarBlock.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};
