/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useUrlState from '@ahooksjs/use-url-state';
import { parseISO } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { Button, Table, Grid, Dropdown, Icon, Modal } from 'semantic-ui-react';

import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import WorkshopModal from './WorkshopModal';
import './WorkshopTable.scss';

import { bem } from '../../../core/design/bem';
import { workshopArrayProptypes, workshopProptypes } from '../../../propTypes';
import { generateWorkshopStatus } from '../../../utils/groupUtils';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';

const css = bem('WorkshopTable');
const translationPrefix = 'workshops.workshop';

function formatDate(date, timezone, format) {
  return moment(parseISO(date)).tz(timezone).format(format);
}

function formatWorkshops(workshops, timezone) {
  const groupByFormat = (date) => formatDate(date, timezone, 'HH:mm');
  const formattedWorkshops = workshops.map((item) => ({
    ...item,
    startDate: formatDate(item?.startDate, timezone, 'YYYY-MM-DD HH:mm:ss'),
    startDateTz: moment(parseISO(item?.startDate)).tz(timezone),
    endDateTz: moment(parseISO(item?.endDate)).tz(timezone),
    groupByTime: `${groupByFormat(item?.startDate)} - ${groupByFormat(item?.endDate)}`,
    day: formatDate(item?.startDate, timezone, 'YYYY-MM-DD'),
  }));
  const days = Object.keys(
    groupBy(workshops, (item) => {
      const value = get(item, 'startDate');
      if (!value) return '';
      return formatDate(item?.startDate, timezone, 'YYYY-MM-DD');
    }),
  );
  return { days, formattedWorkshops };
}

function generateDateGroups(workshops) {
  const groups = groupBy(workshops, (item) => {
    const value = get(item, 'groupByTime');
    if (!value) return '';
    return value;
  });
  return Object.keys(groups)
    .sort()
    .map((key) => ({
      id: key,
      label: key,
      items: groups[key],
      itemsByDay: groupBy(groups[key], 'day'),
    }));
}

const Actions = ({ workshop }) => {
  const { replays = [] } = workshop;
  const { t } = useTranslation();
  return (
    <div className="actions">
      <Button size="tiny" className="watch-later">
        {t(`${translationPrefix}.watch-later`)}
      </Button>
      {replays.length > 0 && (
        <Button size="tiny" className="replay">
          {t(`${translationPrefix}.replay`)}
        </Button>
      )}
    </div>
  );
};

Actions.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};
function WorkshopTable(props) {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [urlState, setUrlState] = useUrlState({});
  const { workshopId } = urlState;
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { filteredWorkshopIds, itemProps = {}, workshopList, timezone } = props;
  const workshops = generateWorkshopStatus(workshopList);
  const { days, formattedWorkshops } = formatWorkshops(workshops, timezone);
  const times = generateDateGroups(
    desktop
      ? formattedWorkshops
      : formattedWorkshops.filter((w) => w.day === (selectedDay || days?.[0])),
  );

  return (
    <Grid stackable className={css().toString()}>
      {!desktop && (
        <Dropdown
          icon="chevron down"
          className={css('days').toString()}
          value={selectedDay || days?.[0]}
          fluid
          selection
          onChange={(_e, { value }) => setSelectedDay(value)}
          options={days.map((day) => ({
            key: day,
            text: t('program.table.day', { day }),
            value: day,
          }))}
        />
      )}
      <Table unstackable striped>
        {desktop && (
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="time" style={{ minWidth: 130, visibility: 'hidden' }} />
              {days.map((day) => (
                <Table.Cell key={day}>{t('program.table.day', { day })}</Table.Cell>
              ))}
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {times.map(({ id, items, itemsByDay }) => {
            const session = items[0];
            return (
              <Table.Row key={id}>
                <Table.Cell className="time">
                  {t('program.table.time', {
                    startDate: session?.startDateTz,
                    endDate: session?.endDateTz,
                  })}
                </Table.Cell>
                {days.map((day) => {
                  const dayItems = get(itemsByDay, day, []);
                  return (
                    <Table.Cell
                      className={css('workshops').toString()}
                      style={{ cursor: 'pointer', padding: 0 }}
                      key={day}
                    >
                      {dayItems.map((workshop) => {
                        // eslint-disable-next-line no-shadow
                        const { _id, workshopId, title } = workshop;
                        const sessionId = workshopId || _id;
                        const isHighlighted = filteredWorkshopIds?.includes(sessionId) || false;
                        return (
                          <div
                            key={sessionId}
                            className={css('workshop', { workshopId: sessionId })
                              .state({ highlighted: isHighlighted })
                              .toString()}
                          >
                            <div
                              className="title"
                              onClick={() => setUrlState({ workshopId: sessionId })}
                            >
                              {title}
                            </div>
                            {desktop && <Actions workshop={workshop} />}
                            {!desktop && (
                              <>
                                <div
                                  style={{ width: 30 }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsOpen(sessionId);
                                  }}
                                >
                                  <Icon className="ellipsis" name="ellipsis vertical" />
                                </div>
                                {isOpen === sessionId && (
                                  <Modal
                                    className={css('options').toString()}
                                    open
                                    closeIcon
                                    onClose={() => setIsOpen(false)}
                                  >
                                    <Modal.Header>Options</Modal.Header>
                                    <Modal.Content>
                                      <Actions workshop={workshop} />
                                    </Modal.Content>
                                  </Modal>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <WorkshopModal
        itemProps={itemProps}
        workshopId={workshopId}
        workshops={workshops}
        onClose={() => setUrlState({ workshopId: undefined })}
        onChange={(sessionId) => setUrlState({ workshopId: sessionId })}
      />
    </Grid>
  );
}

WorkshopTable.defaultProps = {
  filteredWorkshopIds: undefined,
  groupBy: undefined,
  itemProps: {},
  timezone: 'Europe/London',
  workshopList: [],
};

WorkshopTable.propTypes = {
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string),
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  itemProps: PropTypes.object,
  timezone: PropTypes.string,
  workshopList: workshopArrayProptypes,
};

export default WorkshopTable;
