/* eslint-disable import/no-cycle */
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { entityForEvent } from '../../agenda/blocks/UserAgendaBlock';
import EntityLink from '../../components/EntityLink';
import { TrackingContext } from '../../Context';
import store from '../../shared/Store';
import { cancelAppointment } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { getString } from '../../utils';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { localeFormat } from '../../utils/date';
import {
  AddAppointmentToCalendar,
  AddWorkshopToCalendar,
} from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { getClassName } from '../../workshops/utils';
import { useWorkshopRegistration } from '../../workshops/utils/workshopRegistrationUtils';
import UserScheduleWorkshopTemplate from '../blocks/UserScheduleWorkshopTemplate';
import AgendaCarousel from './AgendaCarousel/AgendaCarousel';
import AgendaWeek from './AgendaWeek';

const { endpoints } = window.__DATA__;

const translationPrefix = 'profile';

const DeleteEventButton = ({ event }) => {
  const [loading, setLoading] = useState(false);
  const { unregisterWorkshop } = useWorkshopRegistration(event);
  const { trackEvent } = useContext(TrackingContext);

  async function handleCancelEvent() {
    switch (event.type) {
      case 'workshop': {
        unregisterWorkshop();
        break;
      }
      case 'appointment': {
        cancelAppointment(event, event.sponsor, { setLoading, trackEvent });
        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <Button
      color="red"
      onClick={handleCancelEvent}
      size="tiny"
      icon
      loading={loading}
      disabled={loading}
    >
      <Icon name="remove" />
    </Button>
  );
};

const NavigateAppointment = ({ event, lang }) => {
  const { _id, collection } = event;

  return (
    <Button
      primary
      size="tiny"
      style={{ width: '100%' }}
      target="_blank"
      href={`${endpoints.meetings}/e/${store.eventId}/${lang}/a/c/${collection}/${_id}/u/${store.userId}`}
    >
      {getString(`appointments.access-room`)} <Icon name="arrow right" />
    </Button>
  );
};
NavigateAppointment.propTypes = {
  event: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

const EventsTable = ({ events }) => (
  <Table celled className="hide-mobile-header">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{ width: 200 }}>
          {getString(`${translationPrefix}.timetable`)}
        </Table.HeaderCell>
        <Table.HeaderCell>{getString(`${translationPrefix}.meeting`)}</Table.HeaderCell>
        <Table.HeaderCell style={{ width: 250 }}>
          {getString(`workshops.workshop.add-to-calendar`)}
        </Table.HeaderCell>
        {/* <Table.HeaderCell style={{ width: 180 }}>
          {getString(`${translationPrefix}.join`)}
        </Table.HeaderCell> */}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {events.map((event) => {
        const { _id, appointment, endDate, mandatory, startDate, group, title, type } = event;

        return (
          <Table.Row key={`schedule-workshop-${_id}`} className={getClassName(event)}>
            <Table.Cell>
              {localeFormat(parseISO(startDate), 'p')} - {localeFormat(parseISO(endDate), 'p O')}
            </Table.Cell>
            <Table.Cell>
              <EntityLink entity={entityForEvent(event)}>{title}</EntityLink>
            </Table.Cell>
            <Table.Cell>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {type === 'workshop' && <AddWorkshopToCalendar workshop={event} primary />}
                {type === 'appointment' && (
                  <AddAppointmentToCalendar appointment={appointment} sponsor={group} primary />
                )}
                &nbsp;
                {!mandatory && <DeleteEventButton event={event} />}
              </div>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  </Table>
);

EventsTable.propTypes = {
  events: PropTypes.array.isRequired,
};

const AgendaList = ({ events, title }) => {
  const { days, eventsByDay } = groupEventsByDays(events);
  return (
    <Grid.Row stretched>
      <Grid.Column width={16}>
        <Segment className="segment--schedule">
          <Header as="h3">{title}</Header>
          <p className="description">{getString(`${translationPrefix}.schedule.description`)}</p>
          {days.map((day) => {
            const dayFormat = localeFormat(parseISO(day), 'PPPP');
            const footer = getString(`${translationPrefix}.schedule.footer-${day}`);
            return (
              <>
                <Header as="h4">{dayFormat}</Header>
                <EventsTable events={eventsByDay[day]} />
                {footer && <p className="schedule__footer">{footer}</p>}
              </>
            );
          })}
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const variants = {
  list: AgendaList,
  workshops: UserScheduleWorkshopTemplate,
  agenda: AgendaWeek,
  carousel: AgendaCarousel,
};

const Schedule = (props) => {
  const { container, events, title, variant, _id, className, itemProps, ...rest } = props;

  const Component = variants[variant] || variants.list;

  return (
    <Component
      {...rest}
      _id={_id}
      className={className}
      container={container}
      events={events}
      itemProps={itemProps}
      title={title}
    />
  );
};

Schedule.defaultProps = {
  className: undefined,
  container: undefined,
  itemProps: undefined,
  title: 'Agenda',
  variant: 'list',
};

Schedule.propTypes = {
  _id: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  className: PropTypes.string,
  container: PropTypes.object,
  itemProps: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default Schedule;
