import React from 'react';
import { Card, ContainerProps, SemanticWIDTHS } from 'semantic-ui-react';

import { BlockProps } from '../types';

import { bem } from '../../../core/design/bem';
import { useSpeakerIds } from '../../../core/users/users.hooks';
import BlockContainer from '../../BlockContainer';
import SpeakerCard from '../../speakers/SpeakerCard';

const css = bem('SpeakersBlock');

type SpeakersBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  itemsPerRow?: SemanticWIDTHS;
  speakerIds?: string[];
  variant?: 'classic' | 'square';
} & typeof SpeakersBlockDefaultProps;

const SpeakersBlockDefaultProps = {
  container: {},
  className: '',
  itemsPerRow: 4,

  speakerIds: [],
  variant: 'classic',
};

const SpeakersBlock = ({
  _id,
  className,
  container,
  speakerIds,
  variant,
  itemsPerRow,
  ...rest
}: SpeakersBlockProps): JSX.Element | null => {
  const speakers = useSpeakerIds(speakerIds);
  if (!speakers || speakerIds.length === 0) return null;
  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
        variant,
      }).mix(className)}
    >
      <Card.Group itemsPerRow={itemsPerRow || 6} centered>
        {speakers.map((speaker: any) => (
          <SpeakerCard key={speaker._id} variant={variant} {...{ speaker, ...rest }} />
        ))}
      </Card.Group>
    </BlockContainer>
  );
};

SpeakersBlock.defaultProps = SpeakersBlockDefaultProps;

export default SpeakersBlock;
