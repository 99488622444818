import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';
import { workshopProptypes } from '../../../propTypes';
import EntityLink from '../../EntityLink';
import Images from '../../../utils/Images';
import WorkshopDate from '../WorkshopDate';
import { bem } from '../../../core/design/bem';
import './WorkshopOvhCard.scss';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import WorkshopActions from '../../../workshops/components/WorkshopTable/WorkshopActions';

const css = bem('workshop-card-ovh');

function WorkshopOvhCard(props) {
  const { workshop, agendaCard, tagFields, actions } = props;
  const { title, image, shortDescription, startDate, endDate } = workshop;

  return (
    <div className={css()}>
      <Card as={agendaCard ? EntityLink : undefined} entity={agendaCard ? workshop : undefined}>
        <Card.Content className={css('container').toString()}>
          {image && image.uri && (
            <div
              className="image"
              style={{
                backgroundImage: `url(${Images.cdn(image)})`,
              }}
            >
              {actions.length > 0 && (
                <div className="overlay">
                  <WorkshopActions actions={actions} workshop={workshop} />
                </div>
              )}
            </div>
          )}
          <div className={css('content')}>
            <div style={{ flex: 1 }}>
              {title && (
                <Header className="title" as="h3">
                  {title}
                </Header>
              )}
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} />
              </Card.Description>
              {shortDescription && (
                <Card.Description className="description">{shortDescription}</Card.Description>
              )}
              <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
            </div>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}

WorkshopOvhCard.defaultProps = {
  actions: [],
  agendaCard: false,
  tagFields: [],
};

WorkshopOvhCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  agendaCard: PropTypes.bool,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  workshop: workshopProptypes.isRequired,
};

export default WorkshopOvhCard;
