import { differenceInCalendarDays, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';
import { useAutoRefresh } from '../../utils/hooks';
import CountdownSquare from '../components/CountdownSquare';
import { countdownVariants } from './NextLiveCountdownBlock/variants';

export const getTimeLeft = (now, date) => {
  const then = parseISO(date);
  if (then < now) {
    return null;
  }
  const secondsLeft = Math.floor((then.getTime() - now) / 1000);
  const seconds = secondsLeft % 60;
  const minutes = Math.floor(secondsLeft / 60) % 60;
  const hours = Math.floor(secondsLeft / 3600) % 24;
  const days = Math.floor(secondsLeft / (24 * 3600));

  return { days, hours, minutes, seconds, calendarDays: differenceInCalendarDays(then, now) };
};

export function useTimeLeft(date) {
  const now = useAutoRefresh(1000);
  return getTimeLeft(now, date);
}

const dDayItemList = [{ key: 'dday', label: 'day', valueLabel: 'D' }];

const HtmlHeader = ({ content, ...props }) => {
  if (!content) return null;
  if (content.indexOf('</') !== -1) {
    // Oops, html
    return (
      <Header {...props}>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </Header>
    );
  }
  return <Header {...props} content={content} />;
};

const CountdownBlock = ({
  autoHide,
  background,
  color,
  date,
  inverted,
  itemList,
  template,
  title,
  circleSize,
  ...rest
}) => {
  const timeLeft = useTimeLeft(date);
  if (!timeLeft && autoHide) return null; // Auto-hide
  const Component = countdownVariants[template] || countdownVariants.square;
  const hasData =
    timeLeft &&
    (!itemList || itemList.length > 1 || (itemList.length === 1 && timeLeft.calendarDays > 0));
  return (
    <div className="block--countdown">
      <HtmlHeader as="h2" inverted={inverted} content={title} />
      <Component
        {...rest}
        background={background}
        color={color}
        inverted={inverted}
        itemList={hasData ? itemList : dDayItemList}
        timeLeft={timeLeft}
        type={template}
        circleSize={circleSize}
      />
    </div>
  );
};

CountdownBlock.defaultProps = {
  autoHide: true,
  background: undefined,
  color: undefined,
  inverted: undefined,
  itemList: undefined,
  template: 'square',
  title: undefined,
};

CountdownBlock.propTypes = {
  autoHide: PropTypes.bool,
  template: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  itemList: CountdownSquare.propTypes.itemList,
  date: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default CountdownBlock;
