/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { replaceValues } from '../../utils/stringUtils';
import Store from '../../shared/Store';
import './iframe-block.scss';
import BlockContainer from '../BlockContainer';

function IframeBlock(props) {
  const { autoResize, containerType, title, src, height, iframeProps } = props;
  const resizeIfNeeded = (e) => {
    if (autoResize && src) {
      iFrameResize({ log: false }, e.target);
    }
  };

  if (!src) return null;

  const iframeUrl = replaceValues(src, { ...Store.user, userId: Store.user._id });
  const iframeKey = `${src}-${autoResize ? '?resize' : '?no-resize'}`;
  const iframe = (
    <iframe
      title="title"
      key={iframeKey}
      onLoad={resizeIfNeeded}
      src={iframeUrl}
      height={height}
      width="100%"
      style={{ height }}
      {...iframeProps}
    />
  );
  return (
    <BlockContainer
      type={containerType}
      header={title}
      className="block--iframe"
      style={!title ? { padding: 0 } : undefined}
    >
      {iframe}
    </BlockContainer>
  );
}

IframeBlock.defaultProps = {
  autoResize: true,
  containerType: 'segment-light',
  height: undefined,
  iframeProps: undefined,
  title: undefined,
};

IframeBlock.propTypes = {
  autoResize: PropTypes.bool,
  containerType: BlockContainer.propTypes.type,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iframeProps: PropTypes.object,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default IframeBlock;
