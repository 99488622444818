import cx from 'classnames';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Modal, Header, Button, Icon } from 'semantic-ui-react';

import get from 'lodash/get';
import omit from 'lodash/omit';

import './UserPreferencesModalBlock.scss';

import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';
import { sweetAlert } from '../../../utils/popupUtils';
import { evalValue } from '../../../utils/constraints';
import { useMe } from '../../../profile/hooks';

const css = bem('UserPreferencesModalBlock');

const translationPrefix = 'profile.preferences-modal';

type Topic = {
  key: string;
  label: string;
  value: string;
};

type GridPreferencesProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: Record<string, any>;
  onFollow: (topic: Topic) => void;
  topics?: Topic[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPreferences?: Record<string, any>;
};

const GridPreferences = ({
  topics = [],
  config = {},
  onFollow,
  userPreferences,
}: GridPreferencesProps): React.ReactElement | null => {
  const { itemsPerRow = 4 } = config;
  const { t } = useTranslation();
  return (
    <Card.Group stackable itemsPerRow={itemsPerRow} className={css('preferences').toString()}>
      {topics.map((topic: Topic) => {
        const { value, label } = topic;
        const isActive = get(userPreferences, [value, 'follow'], false);
        return (
          <Card key={value} className="preference">
            <Card.Content>
              <Header as="h4">{label}</Header>
              <Button
                size="tiny"
                basic
                className={cx('follow', { active: isActive })}
                onClick={() => onFollow(topic)}
              >
                {t(`${translationPrefix}.${isActive ? 'followed' : 'follow'}`)}
                {isActive && <Icon name="check" style={{ marginLeft: 6, marginRight: 0 }} />}
              </Button>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

GridPreferences.defaultProps = {
  config: {},
  topics: [],
  userPreferences: {},
};

type UserPreferencesModalProps = {
  topics?: Topic[];
  variant?: 'grid';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: Record<string, any>;
};

const components = {
  grid: GridPreferences,
};

const UserPreferencesModalBlock = ({
  topics = [],
  variant = 'grid',
  config = {},
  condition,
}: UserPreferencesModalProps): React.ReactElement | null => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const user = useMe();
  const isVisible = condition ? evalValue(condition, { user }) : false;
  const [preferences, setPreferences] = useState({});
  useEffect(() => {
    const userPreferences = get(user, 'preferences', {});
    setPreferences(userPreferences);
  }, [user]);
  const Preferences = get(components, variant, GridPreferences);
  const { size } = config;

  const handleClose = () => {
    setIsOpen(false);
    setPreferences({});
  };

  const handleSave = async () => {
    try {
      await store.updateUser({ preferences, updatedAt: moment().toISOString() });
      handleClose();
      await sweetAlert({ text: t(`${translationPrefix}.save-success`), icon: 'success' });
    } catch (e) {
      await sweetAlert({ text: t(`${translationPrefix}.save-failure`), icon: 'error' });
    }
  };

  if (!isVisible || !isOpen) return null;

  const handleFollow = (topic: Topic) => {
    const { value } = topic;
    const actualValue = get(preferences, value, {});
    setPreferences({
      ...preferences,
      [value]: {
        ...omit(topic, ['value', 'label']),
        ...get(preferences, value, {}),
        follow: !actualValue?.follow || false,
      },
    });
  };
  return (
    <Modal
      open={isOpen}
      size={size}
      className={css({ variant }).toString()}
      closeIcon
      onClose={handleClose}
    >
      <Modal.Header className={css('header').toString()}>
        {t(`${translationPrefix}.header`)}
      </Modal.Header>
      <Modal.Content className={css('content').toString()}>
        <Header as="h3" className="text">
          {t(`${translationPrefix}.choose-topics`)}
        </Header>
        <Preferences
          topics={topics}
          config={config}
          userPreferences={preferences}
          onFollow={handleFollow}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSave}>
          {t('btn.save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

UserPreferencesModalBlock.defaultProps = {
  condition: undefined,
  config: {},
  topics: [],
  variant: 'grid',
};

export default UserPreferencesModalBlock;
